import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { WrappersModule } from 'src/app/components/wrappers/wrappers.module';

import { SessionsComponent } from './sessions.component';
import { SessionItemComponent } from './session-item/session-item.component';
import { SessionStatusComponent } from './session-status/session-status.component';

import { CommunicationsFormItem } from './session/communications-form-item/communications-form-item.component';

import { AdressesFormItem } from './session/addresses-form-item/addresses-form-item.component';

import { SessionComponent } from './session/session.component';
import { RouterModule } from '@angular/router';
import { ResultItemComponent } from './result-item/result-item.component';
import { CheckResultStatusComponent } from './check-result-status/check-result-status.component';
import { ComponentsModule } from 'src/app/components/components.module';
import { PipesModule } from 'src/app/_pipes/pipes.module';
import { DocumentsFormItem } from './session/documents-form-item/documents-form-item.component';
import { DocumentsItem } from './session/document-item/document-item.component';
import { ViewPersonComponent } from './views/person/view-person.component';
import { ViewAddressComponent } from './views/address/view-address.component';
import { ViewCommunicationComponent } from './views/communication/view-communication.component';
import { ViewBusinessComponent } from './views/business/view-business.component';
import { TranslateModule } from '@ngx-translate/core';

import { ViewDocumentsComponent } from './views/documents/view-documents.component';
import { SessionCertificateComponent } from './certificate/certificate.component';
import { BusinessDocumentsFormItem } from './session/business-documents-form-item/business-documents-form-item.component';
import { ViewBusinessDocumentsComponent } from './views/view-business-documents/view-business-documents.component';
import { CertificatePublicComponent } from './certificate-public/certificate-public.component';
import { SessionBulkImportComponent } from './session-bulk-import/session-bulk-import.component';
import { CheckStatusModule } from './check-status/check-status.module';
import { SessionTestData } from './session/session-test-data/session-test-data.component';
import { SessionListItemComponent } from './views/session-list-item/session-list-item.component';
import { FormBuilderModule } from 'src/app/components/form-builder/form-builder.module';
import { MaterialModule } from 'src/app/material.module';
import { QrCodeModule } from 'ng-qrcode';



@NgModule({
  declarations: [
    DocumentsItem,
    ViewPersonComponent,
    SessionCertificateComponent,
    CertificatePublicComponent,
    ViewAddressComponent,
    ViewCommunicationComponent,
    ViewBusinessComponent,
    ViewDocumentsComponent,
    ViewBusinessDocumentsComponent,
    SessionsComponent,
    SessionStatusComponent,
    CheckResultStatusComponent,
    SessionItemComponent,
    SessionComponent,
    CommunicationsFormItem,
    ResultItemComponent,
    AdressesFormItem,
    DocumentsFormItem,
    BusinessDocumentsFormItem,
    SessionBulkImportComponent,
    SessionTestData,
    SessionListItemComponent
  ],
  imports: [
    PipesModule,
    ComponentsModule,
    RouterModule,
    CommonModule,
    CheckStatusModule,
    ReactiveFormsModule,
    WrappersModule,
    TranslateModule.forChild(),
    FormBuilderModule,
    MaterialModule,
    QrCodeModule
  ],
  exports: [
    PipesModule,
    SessionListItemComponent
  ]
})
export class SessionsModule { }
