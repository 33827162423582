<item-wrapper #item [mainService]="providerChecksService" [form]="form" navigateList="provider-checks"
  title="Provider Checks" [readPerm]="auth.hasPermission(auth.perm.ProviderRead)"
  [managePerm]="auth.hasPermission(auth.perm.ProviderManage)" [normalizeProps]="['checkTypes']">
  <!-- <pre>{{form.value | json}}</pre> -->


  <form [formGroup]="form" (onsubmit)="item.submit()" fxFlex fxLayout="column">
    <div fxFlex fxLayout="row" fxLayoutGap="16px">
      <mat-form-field fxFlex>
        <mat-label>{{ 'Name' | translate }}</mat-label>
        <input matInput formControlName="name" />
      </mat-form-field>

      <mat-form-field fxFlex>
        <mat-label>{{ 'Check Types' |translate }}</mat-label>
        <mat-select formControlName="checkTypes" multiple [compareWith]="compareWith">
          <mat-option *ngFor="let checkType of checkTypes | keyvalue" [value]="{checkType: checkType.value}">
            {{('checkType_'+checkType.value) | translate}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <mat-form-field fxFlex>
      <mat-label>{{ 'Description' |translate }}</mat-label>
      <textarea matInput formControlName="description" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"></textarea>
    </mat-form-field>
    <div fxFlex fxLayout="row" fxLayoutGap="16px">
      <mat-form-field>
        <mat-label>{{ 'Source Type' | translate }}</mat-label>
        <mat-select formControlName="sourceType">
          <mat-option *ngFor="let _sourceType of sourceType | keyvalue" [value]="_sourceType.value">
            {{('sourceType_'+_sourceType.value) | translate}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'Country' | translate }}</mat-label>
        <mat-select formControlName="country">
          <mat-option *ngFor="let country of countries$ | async" [value]="country?.isoCode2">
            {{ country?.name | translate}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex fxLayout="row" fxLayoutGap="16px">
      <mat-form-field fxFlex>
        <mat-label>{{ 'Provider' | translate }}</mat-label>
        <mat-select formControlName="providerId">
          <mat-option *ngFor="let provider of providersService$ | async" [value]="provider.id">
            {{provider.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field fxFlex>
        <mat-label>{{ 'Fieldset' | translate }}</mat-label>
        <mat-select formControlName="fieldSetId">
          <mat-option *ngFor="let fieldSet of (fieldSets$ | async)?.data" [value]="fieldSet.id">
            {{fieldSet.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
        <div fxFlex fxLayout="row" fxLayoutGap="16px">
        <mat-form-field fxFlex>
          <mat-label>{{ 'CheckStrategy' | translate }}</mat-label>
          <mat-select formControlName="checkStrategy">
            <mat-option *ngFor="let key of checkStrategyEnumKeys()" [value]="checkStrategyEnum[key]">
               {{(checkStrategyEnum[key]) | translate}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field fxFlex>
          <mat-label>{{ 'MatchingRules' | translate }}</mat-label>
          <input matInput formControlName="matchingRules" />
        </mat-form-field>
    </div>

    <!-- <div fxFlex fxLayout="row" fxLayoutGap="16px" *ngIf="auth.hasPermission(auth.perm.ProviderPricingRead)">
      <mat-form-field fxFlex>
        <mat-label>{{ 'Max Rate Monthly' |translate }}</mat-label>
        <input matInput type="number" formControlName="maxRateMonthly" />
        <span matSuffix>req</span>
      </mat-form-field>
      <mat-form-field fxFlex>
        <mat-label>{{ 'Price Per Check' |translate}}</mat-label>
        <input matInput type="number" formControlName="pricePerCheck" />
        <span matPrefix>&euro;</span>
      </mat-form-field>
      <mat-form-field fxFlex>
        <mat-label>{{ 'Cost Per Check'|translate }}</mat-label>
        <input matInput type="number" formControlName="costPerCheck" />
        <span matPrefix>&euro;</span>
      </mat-form-field>
    </div> -->



    <!-- <mat-checkbox matInput type="checkbox" formControlName="isTesting">Is in testing mode</mat-checkbox> -->


  </form>
</item-wrapper>