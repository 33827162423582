import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Apollo, gql } from "apollo-angular";
import { Configuration, Permission } from "../_graphql/schema";
import { AuthService } from "./auth.service";
import { BaseService } from "./base.service";

@Injectable({
  providedIn: "root",
})
export class ConfigurationsService extends BaseService<Configuration> {
  FieldBase = gql`
  fragment FieldBase on Field {
    id
    name
    type
    displayType
    label
    cssClass
    disabled
    description
    visible
    orderNumber
    dependencyPattern
    defaultValue
    validation { 
      required 
      ...on ArrayValidation 
        { min max }
      ...on NumberValidation 
        { min max step } 
      ...on StringValidation 
        { minLength maxLength pattern } 
      ...on DateValidation 
        { minDate maxDate dateLimitStep dateFutureLimit datePastLimit } 
    }
  }
  `
  FieldFull = gql`
  fragment FieldFull on Field {
    ...FieldBase
    items { 
      ...FieldBase
      items {
        ...FieldBase
        items {
          ...FieldBase
          items {
            ...FieldBase
            items {
              ...FieldBase
              items {
                ...FieldBase
              }
            }
          }
        }
      }
    }
  }
  ${this.FieldBase}

  `
  selectOneFields = gql`
    fragment SelectOneFields on Configuration {
      id
      name
      description
      configCode
      allowOverrides
      isTesting
      deleted
      companyId
      company {
        id
        name
        description
      }
      configApplicationId
      configApplication {
        id
        name
        description
      }
      checks {
        id
        name
        providerCheck {
          id
          name
          sourceType
          checkTypes {
            id
            checkType
          }
          checkStrategy
          matchingRules
        }
      }
      fieldSet {
        id name description title isSystemLocked
        rootField {
          ...FieldFull
        }
      }
    }
    ${this.FieldFull}

  `;

  selectAllFields = gql`
    fragment SelectAllFields on Configuration {
      id
      stats {
        count
        ${this.auth.allowedField(Permission.ConfigurationPricingRead, 'price')}
        ${this.auth.allowedField(Permission.ProviderPricingRead, 'cost')}
      }
      name
      deleted
      description
      checks {
        id
        name
        isActive
        pricePerCheck
        providerCheck {
          id
          name
          sourceType
        }
      }
      configCode
      allowOverrides
      isTesting
      #companyId company  { id name description }
      configApplicationId
      configApplicationId
      configApplication {
        id
        name
        description
      }
    }
  `;

  configurationOnCreateSessionQuery = gql`
  query configurationOnCreateSession($id: ID!) {
    configurationOnCreateSession(id: $id) {
      ...SelectOneFields
    }
  }
  ${this.selectOneFields}`;
  constructor(protected apollo: Apollo, protected sb: MatSnackBar, private auth: AuthService) {
    super(apollo, sb);
    this.initGql("configuration", "configurations");
    this.queryParams.sortOrder = "ASC";
    this.queryParams.sortBy = "name";
  }
  public configurationOnCreateSession (id:string) {
    return this.query(this.configurationOnCreateSessionQuery, {id:id});
  }
}
