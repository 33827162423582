
<div class="holder">
  <mat-toolbar fxLayout="row" class="header">
    Result test
    <span fxFlex></span>
    <button mat-icon-button [routerLink]="['../../../../sessions', sessionId]">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
  <main>

    <ng-template #loading>
      <div fxFill fxLayout="column" fxLayoutAlign="center center">
        <div>
          <mat-spinner diameter="40"></mat-spinner>
        </div>
      </div>
    </ng-template>
    <div *ngIf="item else loading">
      <div #panelTop></div>
      <div class="notes" *ngIf="item?.note">
        <h1>Notes</h1>
        <div *ngFor="let note of item.note.split('||')">
          <app-note-item [innerHTML]="note" *ngIf="note"></app-note-item>
        </div>
      </div>
      <table *ngFor="let group of item.groups">
        <tr *ngIf="group.sequenceNumber == 1">
          <td colspan="2">
            <h1>{{group.group | translate}}</h1>
          </td>
        </tr>
        <tr *ngFor="let field of group.fields">
          <th width="240">{{field.field | translate}}</th>

          <td *ngIf="field.field != 'QrCode'">
            <img *ngIf="['Image', 'Images'].indexOf(field.field) > -1" [src]="field.value" width="100%" />
            <a *ngIf="['Url', 'CachedUrl'].indexOf(field.field) !== -1" [href]="field.value" target="_blank" class="link">{{field.value.replace("http://", "").replace("https://","").replace("www.", "")}}</a>
           
              <span *ngIf="['Image', 'Images', 'Url', 'CachedUrl'].indexOf(field.field) == -1">
              {{field.value | translate}}
            </span>
          </td>

          <td *ngIf="field.field == 'QrCode'">
                  <qr-code value="{{field.value}}" size="200" errorCorrectionLevel="M"></qr-code>

          </td>
                      
        </tr>
           
      </table>
    </div>
  </main>

  <form class="footer" fxLayout="column" [formGroup]="form" fxLayoutAlign="start stretch" (submit)="onSubmit(true)"
    *ngIf="auth.hasPermission(auth.perm.CheckResultFlagging)">
    <mat-form-field appearance="fill">
      <mat-label>{{Note | translate}}</mat-label>
      <textarea matInput cdkTextareaAutosize formControlName="note" #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"></textarea>
    </mat-form-field>
    <div fxLayout="row" fxFlex fxLayoutGap="8px">
      <button [disabled]="!form.valid" type="button" (click)="onSubmit(false)" mat-stroked-button color="warn" fxFlex>{{
        'Mark Not Passed' }}</button>
      <button [disabled]="!form.valid" type="submit" mat-raised-button color="primary" fxFlex>{{ 'Mark Passed'
        }}</button>
    </div>
  </form>
</div>