export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Decimal: any;
};

/** Address object type contains data related to address of some company or person */
export type Address = {
  __typename?: 'Address';
  /** Unique identifier of an address represented in string format */
  addressIdentifier?: Maybe<Scalars['String']>;
  /** List of companies with this address */
  businesses?: Maybe<Array<Maybe<Business>>>;
  /** Name of the city */
  city?: Maybe<Scalars['String']>;
  /** Country code information */
  countryCode?: Maybe<Scalars['String']>;
  /** Date and time when the address information was entered for the first time */
  created?: Maybe<Scalars['DateTime']>;
  /** Date and time when address information were deleted */
  deleted?: Maybe<Scalars['DateTime']>;
  /** Unique ID of address */
  id?: Maybe<Scalars['ID']>;
  /** Date and time when address information were last modified */
  modified?: Maybe<Scalars['DateTime']>;
  /** Postal code information */
  postalCode?: Maybe<Scalars['String']>;
  /** Level of priority. This is integer value used during sorting and showing list of addresses. Address object with highest priority represents main address of subject. For example, address with highest priority related with a company represents company headquarters address */
  priority?: Maybe<Scalars['Int']>;
  /** Name of the province */
  province?: Maybe<Scalars['String']>;
  /** Street line 1. Contains information about the civic number and street name (street address) */
  street?: Maybe<Scalars['String']>;
  /** Street line 2 information. This field usually contains apartment, suite, unit number, or other address designation that is not part of the physical street address */
  streetLine2?: Maybe<Scalars['String']>;
  /** Street number */
  streetNumber?: Maybe<Scalars['String']>;
};

/** Input object type used for passing address objects with input data for addresses */
export type AddressInput = {
  /** Address identifier in string format */
  addressIdentifier?: InputMaybe<Scalars['String']>;
  /** City name */
  city?: InputMaybe<Scalars['String']>;
  /** Country code */
  countryCode?: InputMaybe<Scalars['String']>;
  /** Unique ID of address */
  id?: InputMaybe<Scalars['ID']>;
  /** Postal code */
  postalCode?: InputMaybe<Scalars['String']>;
  /** Level of priority. This is integer value used during showing list of addresses. Address object with highest priority represents main address of subject. For example, address with highest priority related with a company represents headquarters of the company */
  priority?: InputMaybe<Scalars['Int']>;
  /** Province name */
  province?: InputMaybe<Scalars['String']>;
  /** Street line 1. Contains information about the civic number and street name (street address) */
  street?: InputMaybe<Scalars['String']>;
  /** Street line 2 information. This field usually contains apartment, suite, unit number, or other address designation that is not part of the physical street address */
  streetLine2?: InputMaybe<Scalars['String']>;
  /** Street number */
  streetNumber?: InputMaybe<Scalars['String']>;
};

/** Application description */
export enum Application {
  /** AmlAPI - description */
  AmlApi = 'AML_API',
  /** AmlMobileSdk - description */
  AmlMobileSdk = 'AML_MOBILE_SDK',
  /** AmlUI - description */
  AmlUi = 'AML_UI',
  /** AmlVerifyApp - description */
  AmlVerifyApp = 'AML_VERIFY_APP'
}

export type ArrayValidation = Validation & {
  __typename?: 'ArrayValidation';
  /** Allowed max elements in the array. */
  max?: Maybe<Scalars['Int']>;
  /** Allowed min elements in the array. */
  min?: Maybe<Scalars['Int']>;
  /** Is the field value required. */
  required?: Maybe<Scalars['Boolean']>;
};

/** BaseValidation description */
export type BaseValidation = Validation & {
  __typename?: 'BaseValidation';
  /** description */
  required?: Maybe<Scalars['Boolean']>;
};

/** Bussiness object type contains data for specific business */
export type Business = {
  __typename?: 'Business';
  /** List of addresses that represents all addresses where this company is located */
  addresses?: Maybe<Array<Maybe<Address>>>;
  /** List of contact information for communication with this company */
  communications?: Maybe<Array<Maybe<Communication>>>;
  /** Unique ID of the company */
  companyId?: Maybe<Scalars['String']>;
  /** Unique ID of the company type */
  companyIdType?: Maybe<Scalars['String']>;
  /** Company name */
  companyName?: Maybe<Scalars['String']>;
  /** Date and time when company information are first created in the system */
  created?: Maybe<Scalars['DateTime']>;
  /** Date and time when this company is deleted from the system */
  deleted?: Maybe<Scalars['DateTime']>;
  /** List of documents that belong to this business */
  documents?: Maybe<Array<Maybe<BusinessDocument>>>;
  /** Field contains session during which this business have been checked for the first time */
  firstSession?: Maybe<Session>;
  /** Company establishment date */
  foundingDate?: Maybe<Scalars['String']>;
  /** Business unique ID */
  id?: Maybe<Scalars['ID']>;
  /** Field contains last session where this business have been checked */
  lastSession?: Maybe<Session>;
  /** Date and time when company information are last modified */
  modified?: Maybe<Scalars['DateTime']>;
  /** Number of sessions during which this business have been checked */
  numberOfSessions?: Maybe<Scalars['Int']>;
  /** List of persons related to this company */
  persons?: Maybe<Array<Maybe<Person>>>;
  /** Unique ID of the referenced external object */
  referenceId?: Maybe<Scalars['String']>;
  /** Name of the referenced external object */
  referenceName?: Maybe<Scalars['String']>;
  /** Current session this business is related to */
  session?: Maybe<Session>;
};

/** Business document object type contains data about document used during business checks performed in sessions */
export type BusinessDocument = {
  __typename?: 'BusinessDocument';
  /** The date and time the company document information is first entered into the system */
  created?: Maybe<Scalars['DateTime']>;
  /** Date and time when the company document information are deleted from the system */
  deleted?: Maybe<Scalars['DateTime']>;
  /** Date and time of document expiration */
  expiry?: Maybe<Scalars['DateTime']>;
  /** Unique ID of business document */
  id?: Maybe<Scalars['ID']>;
  /** Date and time when the company document information are last modified */
  modified?: Maybe<Scalars['DateTime']>;
  /** Number of the business document */
  number?: Maybe<Scalars['String']>;
  /** Type of business document */
  type?: Maybe<Scalars['String']>;
};

/** Input object type used for passing business document objects with input data for business document */
export type BusinessDocumentInput = {
  /** Business document expiration time and date */
  expiry?: InputMaybe<Scalars['DateTime']>;
  /** Unique ID of business document */
  id?: InputMaybe<Scalars['ID']>;
  /** Number of business document */
  number?: InputMaybe<Scalars['String']>;
  /** Type of business document */
  type?: InputMaybe<Scalars['String']>;
};

/** Input object type used for passing objects with data related to particular business */
export type BusinessInput = {
  /** List of addresses that represents all addresses where this company is located */
  addresses?: InputMaybe<Array<InputMaybe<AddressInput>>>;
  /** List of contact information for communication with this company */
  communications?: InputMaybe<Array<InputMaybe<CommunicationInput>>>;
  /** Company identifier in string format */
  companyId?: InputMaybe<Scalars['String']>;
  /** Identifier of company type */
  companyIdType?: InputMaybe<Scalars['String']>;
  /** Company name */
  companyName?: InputMaybe<Scalars['String']>;
  /** List of documents that belong to this business */
  documents?: InputMaybe<Array<InputMaybe<BusinessDocumentInput>>>;
  /** Company establishment date */
  foundingDate?: InputMaybe<Scalars['String']>;
  /** Unique id of business */
  id?: InputMaybe<Scalars['ID']>;
  /** List of persons related to this company */
  persons?: InputMaybe<Array<InputMaybe<PersonInput>>>;
  /** Unique ID of the referenced external object */
  referenceId?: InputMaybe<Scalars['String']>;
};

/** Check object type represents a check performed during sessions */
export type Check = {
  __typename?: 'Check';
  /** List of all available check result fields */
  availableFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** List of all available check groups */
  availableGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Status of this check */
  checkStatus?: Maybe<CheckStatus>;
  /** Type of the check */
  checkType?: Maybe<CheckType>;
  /** Unique ID for check configuration */
  configurationId?: Maybe<Scalars['ID']>;
  /** Cost of this check */
  cost?: Maybe<Scalars['Decimal']>;
  /** Date and time when check is created */
  created?: Maybe<Scalars['DateTime']>;
  /** Date and time when check is deleted */
  deleted?: Maybe<Scalars['DateTime']>;
  /** Description for the check */
  description?: Maybe<Scalars['String']>;
  /** If error happens during check proccessing, this field will contain error code */
  errorCode?: Maybe<Scalars['String']>;
  /** If error happens during check proccessing, this field will contain short description of that error */
  errorDescription?: Maybe<Scalars['String']>;
  /** Date and time when check is finished */
  finished?: Maybe<Scalars['DateTime']>;
  /** Unique check ID */
  id?: Maybe<Scalars['ID']>;
  /** Number of check results */
  matches?: Maybe<Scalars['Int']>;
  /** Date and time when check is last modified */
  modified?: Maybe<Scalars['DateTime']>;
  /** Note for the check - to present the specifics during the check */
  note?: Maybe<Scalars['String']>;
  /** Price of this check */
  price?: Maybe<Scalars['Decimal']>;
  /** This query returns list of check results after check proccessing is finished */
  results?: Maybe<Array<Maybe<CheckResult>>>;
  /** Information for the session this check is associated with */
  session?: Maybe<Session>;
  /** Unique ID of the session this check is associated with */
  sessionId?: Maybe<Scalars['ID']>;
  /** Date and time when check is started */
  started?: Maybe<Scalars['DateTime']>;
};


/** Check object type represents a check performed during sessions */
export type CheckResultsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

/** Input object type used for passing objects with data related to particular check */
export type CheckInput = {
  /** Type of new check */
  checkType?: InputMaybe<CheckType>;
  /** Short description for new check */
  description?: InputMaybe<Scalars['String']>;
};

/** This object type represents check result returned after session check proccess is finished */
export type CheckResult = {
  __typename?: 'CheckResult';
  /** This field contains data for business if check is performed for some business */
  business?: Maybe<Business>;
  /** Category of the check result. Some of possible check result categories are: PASS (Passport), SIP (Special interest person), AdverseMedia, PEP (Politically exposed person), SIE (Special interest entity), TER/SIE (Special interest entity - Terrorist), RCA (Relative or close associate - close to particular PEP), EU_FSF (EU Financial Sanction Files), USA_OFAC (USA Office of Foreign Assets Control - OFAC sanction list), DL, MDC, UNK (Unknown), etc. Category can also be combination of 2 or more previously mentioned categories */
  category?: Maybe<Scalars['String']>;
  /** Date and time when the check result is created */
  created?: Maybe<Scalars['DateTime']>;
  /** Date and time when the check result is deleted */
  deleted?: Maybe<Scalars['DateTime']>;
  /** Type of the entity check is performed on (person or business) */
  entityType?: Maybe<Scalars['String']>;
  /** Unique ID of the entity check is performed on (person or business) */
  entityUniqueId?: Maybe<Scalars['String']>;
  /** If error happened during creation of this check results, this field will contain unique code for that error */
  errorCode?: Maybe<Scalars['String']>;
  /** If error happened during creation of this check results, this field will contain short description for that error */
  errorDescription?: Maybe<Scalars['String']>;
  /** Date and time when this check result is marked as false positive */
  falsePositive?: Maybe<Scalars['DateTime']>;
  falsePositiveUser?: Maybe<User>;
  /** Unique ID of the user who marked this check result as false positive */
  falsePositiveUserId?: Maybe<Scalars['ID']>;
  /** List of check result groups whose names are mathing with the strings passed as an argument. If no argument is specified, all groups for this check result are returned. Groups are ordered by their priority */
  groups?: Maybe<Array<Maybe<CheckResultGroup>>>;
  /** Unique ID for this check result */
  id?: Maybe<Scalars['ID']>;
  /** Calculated rate of how well a company's data set "matches" or overlaps with the data in the check results. Value can be any number from 0 to 100 represented in string format */
  matchRate?: Maybe<Scalars['String']>;
  /** Date and time when the check result is modified */
  modified?: Maybe<Scalars['DateTime']>;
  /** Short note for this check result */
  note?: Maybe<Scalars['String']>;
  /** This field contains data for person if check is performed for some individual */
  person?: Maybe<Person>;
  /** Check object this check result is related to */
  sessionCheck?: Maybe<Check>;
  /** Unique ID of the session check this check result is related to */
  sessionCheckId?: Maybe<Scalars['ID']>;
  /** Status of the check result. Possible values are "false-positive", "true-positive" and "pending". The first check results returned will always have "pending" status by default */
  status?: Maybe<Scalars['String']>;
  /** Date and time when this check result is marked as true positive */
  truePositive?: Maybe<Scalars['DateTime']>;
  truePositiveUser?: Maybe<User>;
  /** Unique ID of the user who marked this check result as true positive */
  truePositiveUserId?: Maybe<Scalars['ID']>;
};


/** This object type represents check result returned after session check proccess is finished */
export type CheckResultGroupsArgs = {
  names?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** This type is used as complex custom field of CheckResultGroup object type */
export type CheckResultField = {
  __typename?: 'CheckResultField';
  /** Date and time the check result field is created */
  created?: Maybe<Scalars['DateTime']>;
  /** Date and time the check result field is deleted */
  deleted?: Maybe<Scalars['DateTime']>;
  /** Name of this custom check result field */
  field?: Maybe<Scalars['String']>;
  /** Check result group that contains this field */
  group?: Maybe<CheckResultGroup>;
  /** Unique ID of the check result group object that contains this field */
  groupId?: Maybe<Scalars['ID']>;
  /** Unique ID of the check result field */
  id?: Maybe<Scalars['ID']>;
  /** Date and time the check result field is last modified */
  modified?: Maybe<Scalars['DateTime']>;
  /** Priority of the check result field */
  priority?: Maybe<Scalars['Int']>;
  /** Value of this custom check result field */
  value?: Maybe<Scalars['String']>;
};

/** This type contains information about specific group a check result could have. */
export type CheckResultGroup = {
  __typename?: 'CheckResultGroup';
  /** Check result object this group is related to */
  checkResult?: Maybe<CheckResult>;
  /** Unique ID of the check result object  */
  checkResultId?: Maybe<Scalars['ID']>;
  /** Date and time the check result group is created */
  created?: Maybe<Scalars['DateTime']>;
  /** Date and time the check result field is deleted */
  deleted?: Maybe<Scalars['DateTime']>;
  /** Short description for this check result group */
  description?: Maybe<Scalars['String']>;
  /** List of custom check result fields related to this group */
  fields?: Maybe<Array<Maybe<CheckResultField>>>;
  /** Name of the check result group represented as string */
  group?: Maybe<Scalars['String']>;
  /** Unique ID of the check result group */
  id?: Maybe<Scalars['ID']>;
  /** Flag that indicates whether this is the primary check result group or not */
  isPrimary?: Maybe<Scalars['Boolean']>;
  /** Date and time the check result group is last modified */
  modified?: Maybe<Scalars['DateTime']>;
  /** Priority is of this check result group. It is represented as integer value where group with lower value has higher priority and it is used for ordering groups */
  priority?: Maybe<Scalars['Int']>;
  /** Sequence number of the check result group */
  sequenceNumber?: Maybe<Scalars['Int']>;
};

/** Enumeration type that represents all statuses one check could have during its lifecycle */
export enum CheckStatus {
  /** The session will return this result and an error message in case of any problems while processing the input data */
  Error = 'ERROR',
  /** Check will have this status when it is launched in a session */
  Initiated = 'INITIATED',
  /** Check will have this status if one or more of its check results are true positive or pending */
  NotPassed = 'NOT_PASSED',
  /** Check will have this status if all of its check results are false positive, and there are no true positive results */
  Passed = 'PASSED',
  /** Check will have this status as long as it is being processed. It will remain in this status until it is finished */
  Processing = 'PROCESSING',
  /** Session check is evoked. This is internal status and will not be presented to end-user */
  Started = 'STARTED',
  /** The input data is validated. This is internal status and will not be presented to the end-user */
  ValidInput = 'VALID_INPUT'
}

/** CheckStrategy description */
export enum CheckStrategy {
  AllAtOnce = 'ALL_AT_ONCE',
  OneByOne = 'ONE_BY_ONE'
}

/** Enumeration type that represents all supported check types */
export enum CheckType {
  /** Address check. (NOT IMPLEMENTED) */
  Address = 'ADDRESS',
  /** Banking API check. (NOT IMPLEMENTED) */
  BankingApi = 'BANKING_API',
  /** Bank identifier code check. (NOT IMPLEMENTED) */
  BankId = 'BANK_ID',
  /** Business adverse media check */
  BusinessAdverseMedia = 'BUSINESS_ADVERSE_MEDIA',
  /** Politically exposed person check for business. */
  BusinessPep = 'BUSINESS_PEP',
  /** Bussiness sanction list check */
  BusinessSanctionList = 'BUSINESS_SANCTION_LIST',
  /** Business validation check. (NOT IMPLEMENTED) */
  BusinessValidation = 'BUSINESS_VALIDATION',
  /** Citizens check */
  Citizens = 'CITIZENS',
  /** Consumer check */
  Consumer = 'CONSUMER',
  /** Credit Bureau check */
  CreditBureau = 'CREDIT_BUREAU',
  /** Credit Bureau #2 check */
  CreditBureau_2 = 'CREDIT_BUREAU_2',
  Death = 'DEATH',
  /** Document forgery check. (NOT IMPLEMENTED) */
  DocumentForgery = 'DOCUMENT_FORGERY',
  /** Document identifier check. (NOT IMPLEMENTED) */
  DocumentId = 'DOCUMENT_ID',
  /** Document photography check. (NOT IMPLEMENTED) */
  DocumentPhoto = 'DOCUMENT_PHOTO',
  Email = 'EMAIL',
  /** Goverment identity check */
  GovernmentIdentityCheck = 'GOVERNMENT_IDENTITY_CHECK',
  /** Location check. (NOT IMPLEMENTED) */
  Location = 'LOCATION',
  /** Business adverse media check during time frame. */
  MonitoringBusinessAdverseMedia = 'MONITORING_BUSINESS_ADVERSE_MEDIA',
  /** Politically exposed person check during time frame for business. */
  MonitoringBusinessPep = 'MONITORING_BUSINESS_PEP',
  /** Business sanction list check during time frame */
  MonitoringBusinessSanctions = 'MONITORING_BUSINESS_SANCTIONS',
  /** Personal adverse media check during time frame */
  MonitoringPersonalAdverseMedia = 'MONITORING_PERSONAL_ADVERSE_MEDIA',
  /** Check for politically exposed individual during time frame */
  MonitoringPersonalPep = 'MONITORING_PERSONAL_PEP',
  /** Personal sanction list check during time frame */
  MonitoringPersonalSanctions = 'MONITORING_PERSONAL_SANCTIONS',
  /** Check has this type if its related provider check type could not be found and resolved */
  None = 'NONE',
  Payroll = 'PAYROLL',
  /** Personal check */
  Personal = 'PERSONAL',
  /** Personal adverse media check */
  PersonalAdverseMedia = 'PERSONAL_ADVERSE_MEDIA',
  /** Check for politically exposed individual */
  PersonalPep = 'PERSONAL_PEP',
  /** Personal sanction list check */
  PersonalSanctionList = 'PERSONAL_SANCTION_LIST',
  /** Phone check */
  Phone = 'PHONE',
  Property = 'PROPERTY',
  /** Residence database check */
  Residential = 'RESIDENTIAL',
  /** Utility services check */
  Utility = 'UTILITY',
  /** Visa check */
  Visa = 'VISA'
}

export type CombinedTestEntityGType = {
  __typename?: 'CombinedTestEntityGType';
  businesses?: Maybe<Array<Maybe<Business>>>;
  checkType?: Maybe<CheckType>;
  /** If present, then this entity is usable only for given country. If null - applicable everywhere. */
  country?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  persons?: Maybe<Array<Maybe<Person>>>;
};

/** Type is used for encapsulating contact information for person or company */
export type Communication = {
  __typename?: 'Communication';
  /** List of all businesses with contact information represented by this object */
  businesses?: Maybe<Array<Maybe<Business>>>;
  /** Additional unique identifier for this communication object */
  communicationIdentifier?: Maybe<Scalars['String']>;
  /** Date and time when the communication information is entered into the system */
  created?: Maybe<Scalars['DateTime']>;
  /** Date and time the communication information is deleted */
  deleted?: Maybe<Scalars['DateTime']>;
  /** E-mail of person or company */
  email?: Maybe<Scalars['String']>;
  /** Unique ID of communication type */
  id?: Maybe<Scalars['ID']>;
  /** IP address of the host machine that could be used for communication with certain person */
  ipAddress?: Maybe<Scalars['String']>;
  /** URL to LinkedIn account for business or person */
  linkedIn?: Maybe<Scalars['String']>;
  /** Date and time the communication information is last modified */
  modified?: Maybe<Scalars['DateTime']>;
  /** List of all persons with contact information represented by this object */
  persons?: Maybe<Array<Maybe<Person>>>;
  /** Phone number of person or company represented as string */
  phone?: Maybe<Scalars['String']>;
  /** Priority level is integer value used for sorting communication objects by their priority. Communication object with highest priority represents primary way of communication with certain businness or person */
  priority?: Maybe<Scalars['Int']>;
  /** URL of website that belongs to certain person or company */
  website?: Maybe<Scalars['String']>;
  /** IPv4 address of the website that belongs to certain person or company */
  websiteIp?: Maybe<Scalars['String']>;
};

/** Input object type used for passing objects that contains contact information for person or company */
export type CommunicationInput = {
  /** Additional unique identifier for this communication object */
  communicationIdentifier?: InputMaybe<Scalars['String']>;
  /** E-mail of person or company */
  email?: InputMaybe<Scalars['String']>;
  /** Unique ID for communication object */
  id?: InputMaybe<Scalars['ID']>;
  /** IP address of the host machine that could be used for communication with certain person */
  ipAddress?: InputMaybe<Scalars['String']>;
  /** URL to LinkedIn account for business or person */
  linkedIn?: InputMaybe<Scalars['String']>;
  /** Phone number of person or company represented as string */
  phone?: InputMaybe<Scalars['String']>;
  /** Priority level is integer value used for sorting communication objects by their priority. Communication object with highest priority represents primary way of communication with certain businness or person */
  priority?: InputMaybe<Scalars['Int']>;
  /** URL of website that belongs to certain person or company */
  website?: InputMaybe<Scalars['String']>;
  /** IPv4 address of the website that belongs to certain person or company */
  websiteIp?: InputMaybe<Scalars['String']>;
};

/** Company description */
export type Company = {
  __typename?: 'Company';
  /** description */
  address?: Maybe<Scalars['String']>;
  /** description */
  address2?: Maybe<Scalars['String']>;
  /** description */
  adminNote?: Maybe<Scalars['String']>;
  /** Allowed payment method, Credit card is a PrePaid and Invoicing is PostPaid */
  allowedPaymentMethod?: Maybe<PaymentMethod>;
  /** description */
  avatarId?: Maybe<Scalars['ID']>;
  /** description */
  category?: Maybe<CompanyCategory>;
  /** description */
  categoryId?: Maybe<Scalars['ID']>;
  /** description */
  checks?: Maybe<Array<Maybe<CompanyCheck>>>;
  /** description */
  city?: Maybe<Scalars['String']>;
  /** description */
  contactPersonName?: Maybe<Scalars['String']>;
  /** description */
  contactPersonPosition?: Maybe<Scalars['String']>;
  /** description */
  country?: Maybe<Country>;
  /** description */
  countryId?: Maybe<Scalars['ID']>;
  /** description */
  coverId?: Maybe<Scalars['ID']>;
  /** description */
  created?: Maybe<Scalars['DateTime']>;
  /** description */
  currentSubscription?: Maybe<Purchase>;
  /** description */
  deleted?: Maybe<Scalars['DateTime']>;
  /** description */
  description?: Maybe<Scalars['String']>;
  /** description */
  email?: Maybe<Scalars['String']>;
  /** description */
  facebook?: Maybe<Scalars['String']>;
  /** description */
  id?: Maybe<Scalars['ID']>;
  /** description */
  idNumber?: Maybe<Scalars['String']>;
  /** description */
  ipAddresses?: Maybe<Scalars['String']>;
  /** description */
  linkedin?: Maybe<Scalars['String']>;
  /** description */
  modified?: Maybe<Scalars['DateTime']>;
  /** description */
  name?: Maybe<Scalars['String']>;
  /** description */
  nextSubscription?: Maybe<Purchase>;
  /** description */
  phoneNumber?: Maybe<Scalars['String']>;
  /** description */
  pricePerCheck?: Maybe<Scalars['Decimal']>;
  /** description */
  revShare?: Maybe<Scalars['Decimal']>;
  /** description */
  state?: Maybe<Scalars['String']>;
  stats?: Maybe<Stats>;
  /** description */
  status?: Maybe<CompanyStatus>;
  /** description */
  statusUserNote?: Maybe<Scalars['String']>;
  /** description */
  users?: Maybe<Array<Maybe<User>>>;
  /** description */
  vatNumber?: Maybe<Scalars['String']>;
  /** description */
  website?: Maybe<Scalars['String']>;
  /** description */
  zip?: Maybe<Scalars['String']>;
};


/** Company description */
export type CompanyStatsArgs = {
  pageRequestInput?: InputMaybe<PageRequestInput>;
};

/** Company category description */
export type CompanyCategory = {
  __typename?: 'CompanyCategory';
  /** description */
  created?: Maybe<Scalars['DateTime']>;
  /** description */
  deleted?: Maybe<Scalars['DateTime']>;
  /** description */
  description?: Maybe<Scalars['String']>;
  /** description */
  id?: Maybe<Scalars['ID']>;
  /** description */
  modified?: Maybe<Scalars['DateTime']>;
  /** description */
  name: Scalars['String'];
};

/** Company check object type contains information about checks performed on certain company */
export type CompanyCheck = {
  __typename?: 'CompanyCheck';
  /** Type of this company check. It is resolved as provider check type if one exists or NONE otherwise */
  checkType?: Maybe<CheckType>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['ID']>;
  /** List of all configurations where this company check is available */
  configurations?: Maybe<Array<Maybe<Configuration>>>;
  /** Date and time when the company check is created */
  created?: Maybe<Scalars['DateTime']>;
  deleted?: Maybe<Scalars['DateTime']>;
  /** Short description for this company check */
  description?: Maybe<Scalars['String']>;
  /** Unique ID of company check */
  id?: Maybe<Scalars['ID']>;
  /** This value is set to "true" if this company check is in active state */
  isActive?: Maybe<Scalars['Boolean']>;
  /** This value is set to "true" if this company check is ready to be used */
  isEnabled?: Maybe<Scalars['Boolean']>;
  maxRateMonthly?: Maybe<Scalars['Int']>;
  /** Date and time when the company check is modified */
  modified?: Maybe<Scalars['DateTime']>;
  /** Company check name */
  name: Scalars['String'];
  pricePerCheck?: Maybe<Scalars['Decimal']>;
  providerCheck?: Maybe<ProviderCheck>;
  providerCheckId?: Maybe<Scalars['ID']>;
  revShare?: Maybe<Scalars['Decimal']>;
  stats?: Maybe<Stats>;
};


/** Company check object type contains information about checks performed on certain company */
export type CompanyCheckStatsArgs = {
  pageRequestInput?: InputMaybe<PageRequestInput>;
};

export type CompanyCheckInput = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  maxRateMonthly?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  pricePerCheck?: InputMaybe<Scalars['Decimal']>;
  providerCheckId?: InputMaybe<Scalars['ID']>;
  revShare?: InputMaybe<Scalars['Decimal']>;
};

export type CompanyInput = {
  address?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  avatarId?: InputMaybe<Scalars['ID']>;
  categoryId?: InputMaybe<Scalars['ID']>;
  city?: InputMaybe<Scalars['String']>;
  contactPersonName?: InputMaybe<Scalars['String']>;
  contactPersonPosition?: InputMaybe<Scalars['String']>;
  countryId?: InputMaybe<Scalars['ID']>;
  coverId?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  facebook?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  idNumber?: InputMaybe<Scalars['String']>;
  ipAddresses?: InputMaybe<Scalars['String']>;
  linkedin?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  pricePerCheck?: InputMaybe<Scalars['Decimal']>;
  revShare?: InputMaybe<Scalars['Decimal']>;
  state?: InputMaybe<Scalars['String']>;
  subscriptionId?: InputMaybe<Scalars['ID']>;
  vatNumber?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

/** Enumeration type that represents all statuses a company could have */
export enum CompanyStatus {
  /** Company is in this status if it is successfully activated by the system administrato */
  Active = 'ACTIVE',
  /** Company is in this status if it is registered on the platform and waiting on review and activation by the system administrator. */
  Applied = 'APPLIED',
  /** Company is in this status if it is canceled by the system or company administrator. User cannot log into the system as the company account is canceled. */
  Canceled = 'CANCELED',
  /** Company is set to this status by company or system administrator if information entered during the registration proccess are incorrect or the company is suspicious for any reason. The user will not be able to log into the system. */
  Declined = 'DECLINED',
  /** Company is in status paused if its user can log into the system, but is not able to create and run sessions. */
  Paused = 'PAUSED',
  /** Company is in this status if it is temporarily suspended by the system or company administrator. */
  Suspended = 'SUSPENDED'
}

export type CompanyToken = {
  __typename?: 'CompanyToken';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['ID']>;
  created?: Maybe<Scalars['DateTime']>;
  deleted?: Maybe<Scalars['DateTime']>;
  expiration?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  ipWhitelist?: Maybe<Scalars['String']>;
  isAllowed?: Maybe<Scalars['Boolean']>;
  lastUsed?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  permissions?: Maybe<Array<Maybe<Permission>>>;
  token?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['ID']>;
  webhookAuthKey?: Maybe<Scalars['String']>;
  webhookUrl?: Maybe<Scalars['String']>;
};

export type CompanyTokenInput = {
  expiration?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  ipWhitelist?: InputMaybe<Scalars['String']>;
  isAllowed?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  permissions?: InputMaybe<Array<InputMaybe<Permission>>>;
  token?: InputMaybe<Scalars['String']>;
  webhookAuthKey?: InputMaybe<Scalars['String']>;
  webhookUrl?: InputMaybe<Scalars['String']>;
};

/** ConfigApplication description */
export type ConfigApplication = {
  __typename?: 'ConfigApplication';
  /** description */
  application?: Maybe<Application>;
  /** description */
  company?: Maybe<Company>;
  /** description */
  companyId?: Maybe<Scalars['ID']>;
  /** description */
  configurations?: Maybe<Array<Maybe<Configuration>>>;
  /** description */
  created?: Maybe<Scalars['DateTime']>;
  /** description */
  defaultResponse?: Maybe<ResponseTransport>;
  /** description */
  deleted?: Maybe<Scalars['DateTime']>;
  /** description */
  description?: Maybe<Scalars['String']>;
  /** description */
  id?: Maybe<Scalars['ID']>;
  /** description */
  isTesting?: Maybe<Scalars['Boolean']>;
  /** description */
  matchingFormula?: Maybe<Scalars['String']>;
  /** description */
  modified?: Maybe<Scalars['DateTime']>;
  /** description */
  name?: Maybe<Scalars['String']>;
  /** description */
  pricePerCheck?: Maybe<Scalars['Decimal']>;
  /** description */
  revShare?: Maybe<Scalars['Decimal']>;
};

export type ConfigApplicationInput = {
  checkType?: InputMaybe<CheckType>;
  defaultResponse?: InputMaybe<ResponseTransport>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  isTesting?: InputMaybe<Scalars['Boolean']>;
  matchingFormula?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  pricePerCheck?: InputMaybe<Scalars['Decimal']>;
  revShare?: InputMaybe<Scalars['Decimal']>;
};

/** Configuration object type contains basic information for configuration used when creating sessions */
export type Configuration = {
  __typename?: 'Configuration';
  allowOverrides?: Maybe<Scalars['Boolean']>;
  /** All check types contained within included company checks into configuration. */
  checkTypes?: Maybe<Array<Maybe<CheckType>>>;
  /** List of available checks for this configuration */
  checks?: Maybe<Array<Maybe<CompanyCheck>>>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['ID']>;
  configApplication?: Maybe<ConfigApplication>;
  configApplicationId?: Maybe<Scalars['ID']>;
  configCode?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  deleted?: Maybe<Scalars['DateTime']>;
  /** Short description for this configuration */
  description?: Maybe<Scalars['String']>;
  fieldSet?: Maybe<FieldSet>;
  /** description */
  fieldSetId?: Maybe<Scalars['ID']>;
  /** Unique ID of the configuration */
  id?: Maybe<Scalars['ID']>;
  inactiveChecks?: Maybe<Scalars['Int']>;
  isTesting?: Maybe<Scalars['Boolean']>;
  modified?: Maybe<Scalars['DateTime']>;
  /** Configuration name */
  name?: Maybe<Scalars['String']>;
  stats?: Maybe<Stats>;
};


/** Configuration object type contains basic information for configuration used when creating sessions */
export type ConfigurationStatsArgs = {
  pageRequestInput?: InputMaybe<PageRequestInput>;
};

export type ConfigurationInput = {
  allowOverrides?: InputMaybe<Scalars['Boolean']>;
  checks?: InputMaybe<Array<InputMaybe<CompanyCheckInput>>>;
  configApplicationId?: InputMaybe<Scalars['ID']>;
  configCode?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  fieldSetId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  isTesting?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Country type contains basic data for some specific country */
export type Country = {
  __typename?: 'Country';
  /** Date and time of creation */
  created?: Maybe<Scalars['DateTime']>;
  /** Date and time of deletion */
  deleted?: Maybe<Scalars['DateTime']>;
  /** Country unique ID */
  id?: Maybe<Scalars['ID']>;
  /** Country long name */
  intlName?: Maybe<Scalars['String']>;
  /** Is default country */
  isDefault?: Maybe<Scalars['Boolean']>;
  /** Country 2 letter ISO code */
  isoCode2?: Maybe<Scalars['String']>;
  /** Country 3 letter ISO code */
  isoCode3?: Maybe<Scalars['String']>;
  /** Date and time of last modification */
  modified?: Maybe<Scalars['DateTime']>;
  /** Country name */
  name: Scalars['String'];
  /** Integer value used as sorting criteria */
  order?: Maybe<Scalars['Int']>;
  /** List of states in the country */
  states?: Maybe<Array<Maybe<State>>>;
};

export type CountryInput = {
  id?: InputMaybe<Scalars['ID']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  isoCode2?: InputMaybe<Scalars['String']>;
  isoCode3?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  order?: InputMaybe<Scalars['Int']>;
};

export type Dashboard = {
  __typename?: 'Dashboard';
  businessSessionsCount?: Maybe<Scalars['Int']>;
  businessWithPersonsSessionsCount?: Maybe<Scalars['Int']>;
  checksCount?: Maybe<Scalars['Int']>;
  monitoringsCount?: Maybe<Scalars['Int']>;
  pendingChecksCount?: Maybe<Scalars['Int']>;
  personSessionsCount?: Maybe<Scalars['Int']>;
  sessionsCount?: Maybe<Scalars['Int']>;
};

export enum DatabaseSource {
  EuFsf = 'EU_FSF',
  UsaOfac = 'USA_OFAC'
}

export type DateRangeInput = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  range?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

/** DateValidation description */
export type DateValidation = Validation & {
  __typename?: 'DateValidation';
  /** description */
  dateFutureLimit?: Maybe<Scalars['Int']>;
  /** description */
  dateLimitStep?: Maybe<Scalars['String']>;
  /** description */
  datePastLimit?: Maybe<Scalars['Int']>;
  /** description */
  maxDate?: Maybe<Scalars['Int']>;
  /** description */
  minDate?: Maybe<Scalars['Int']>;
  /** description */
  required?: Maybe<Scalars['Boolean']>;
};

/** Document type represents personal document such as identity card, passport, driver licence, credit card etc. */
export type Document = {
  __typename?: 'Document';
  /** If document is of type MediCard, this field contains type of medicare card */
  cardType?: Maybe<Scalars['String']>;
  /** City where the document was issued */
  cityOfIssue?: Maybe<Scalars['String']>;
  /** Country where the document was issued */
  country?: Maybe<Scalars['String']>;
  /** County where the document was issued */
  countyOfIssue?: Maybe<Scalars['String']>;
  /** Date and time when document was first entered into the system */
  created?: Maybe<Scalars['DateTime']>;
  /** Date and time when document was deleted */
  deleted?: Maybe<Scalars['DateTime']>;
  /** District where the document was issued */
  districtOfIssue?: Maybe<Scalars['String']>;
  /** Date and time of document expiration */
  expiry?: Maybe<Scalars['DateTime']>;
  /** Unique ID of document */
  id?: Maybe<Scalars['ID']>;
  /** Document identifier type */
  idType?: Maybe<Scalars['String']>;
  /** List of images related to this document */
  images?: Maybe<Array<Maybe<DocumentImage>>>;
  /** If document is of type MediCard, this field will contain reference number for medicare card */
  individualReferenceNumber?: Maybe<Scalars['String']>;
  /** Date and time when document was last modified */
  modified?: Maybe<Scalars['DateTime']>;
  /** Data from machine readable zone 1 of the document */
  mrz1?: Maybe<Scalars['String']>;
  /** Data from machine readable zone 2 of the document */
  mrz2?: Maybe<Scalars['String']>;
  /** Number that uniquely represents document regardless of document type */
  number?: Maybe<Scalars['String']>;
  /** Province where the document was issued */
  provinceOfIssue?: Maybe<Scalars['String']>;
  /** Number that represents document serial number regardless of document type */
  serialNumber?: Maybe<Scalars['String']>;
  /** State where the document was issued */
  state?: Maybe<Scalars['String']>;
  /** Type of the document */
  type?: Maybe<DocumentType>;
  /** Version number of the document */
  version?: Maybe<Scalars['String']>;
};

/** Document image type objects contain image data related to particular document */
export type DocumentImage = {
  __typename?: 'DocumentImage';
  /** Date and time when document image was first entered into the system */
  created?: Maybe<Scalars['DateTime']>;
  /** Date and time when document was deleted */
  deleted?: Maybe<Scalars['DateTime']>;
  /** Short description for the document image */
  description?: Maybe<Scalars['String']>;
  /** Document object this image is related to */
  document?: Maybe<Document>;
  /** Unique ID of the document this image is related to */
  documentId?: Maybe<Scalars['ID']>;
  /** Unique Id of the file related to this document image */
  fileId?: Maybe<Scalars['ID']>;
  /** Unique ID of document image */
  id?: Maybe<Scalars['ID']>;
  /** Image data encoded in base64 format */
  imageBase64?: Maybe<Scalars['String']>;
  /** UNIMPLEMENTED */
  imagePath?: Maybe<Scalars['String']>;
  /** Date and time when document image was last modified */
  modified?: Maybe<Scalars['DateTime']>;
  /** Document name */
  name?: Maybe<Scalars['String']>;
};

/** Input object type used for passing objects with image data related to particular document */
export type DocumentImageInput = {
  /** Short description for the document image */
  description?: InputMaybe<Scalars['String']>;
  /** Unique ID of the document object this image relates to */
  documentId?: InputMaybe<Scalars['ID']>;
  /** Unique Id of the file related to this document image */
  fileId?: InputMaybe<Scalars['ID']>;
  /** Unique ID of document image */
  id?: InputMaybe<Scalars['ID']>;
  /** Image data encoded in base64 format */
  imageBase64?: InputMaybe<Scalars['String']>;
  /** UNIMPLEMENTED */
  imagePath?: InputMaybe<Scalars['String']>;
  /** Document name */
  name?: InputMaybe<Scalars['String']>;
};

/** Input object type used for passing object with data related to document such as such as identity card, passport, driver licence, credit card etc. */
export type DocumentInput = {
  /** If document is of type MediCard, this field contains type of medicare card */
  cardType?: InputMaybe<Scalars['String']>;
  /** City where the document was issued */
  cityOfIssue?: InputMaybe<Scalars['String']>;
  /** Country where the document was issued */
  country?: InputMaybe<Scalars['String']>;
  /** County where the document was issued */
  countyOfIssue?: InputMaybe<Scalars['String']>;
  /** District where the document was issued */
  districtOfIssue?: InputMaybe<Scalars['String']>;
  /** Date and time of document expiration */
  expiry?: InputMaybe<Scalars['DateTime']>;
  /** Unique ID of document */
  id?: InputMaybe<Scalars['ID']>;
  /** List of images related to this document */
  images?: InputMaybe<Array<InputMaybe<DocumentImageInput>>>;
  /** If document is of type MediCard, this field should contain reference number for medicare card */
  individualReferenceNumber?: InputMaybe<Scalars['String']>;
  /** Data from machine readable zone 1 of the document */
  mrz1?: InputMaybe<Scalars['String']>;
  /** Data from machine readable zone 2 of the document */
  mrz2?: InputMaybe<Scalars['String']>;
  /** Number that uniquely represents document regardless of document type */
  number?: InputMaybe<Scalars['String']>;
  /** Province where the document was issued */
  provinceOfIssue?: InputMaybe<Scalars['String']>;
  /** Number that represents document serial number regardless of document type */
  serialNumber?: InputMaybe<Scalars['String']>;
  /** State where the document was issued */
  state?: InputMaybe<Scalars['String']>;
  /** Type of the document */
  type?: InputMaybe<DocumentType>;
  /** Version number of the document */
  version?: InputMaybe<Scalars['String']>;
};

/** Enumeration type that represents supported types of document used for performing checks */
export enum DocumentType {
  /** Bank statement */
  BankStatement = 'BANK_STATEMENT',
  /** Birth certificate */
  BirthCertificate = 'BIRTH_CERTIFICATE',
  /** Centrelink card */
  CentrelinkCard = 'CENTRELINK_CARD',
  /** Credit card */
  CreditCard = 'CREDIT_CARD',
  /** Custom document type */
  Custom = 'CUSTOM',
  /** Driver licence */
  DriverLicence = 'DRIVER_LICENCE',
  /** ID card */
  IdCard = 'ID_CARD',
  /** Immigration card */
  ImmiCard = 'IMMI_CARD',
  /** Medical card */
  MediCard = 'MEDI_CARD',
  /** None */
  None = 'NONE',
  /** Passport */
  Passport = 'PASSPORT',
  /** Utility bill */
  UtilityBill = 'UTILITY_BILL',
  /** Visa */
  Visa = 'VISA'
}

/** Feature description */
export type Feature = {
  __typename?: 'Feature';
  /** description */
  created?: Maybe<Scalars['DateTime']>;
  /** description */
  deleted?: Maybe<Scalars['DateTime']>;
  /** description */
  description?: Maybe<Scalars['String']>;
  /** description */
  icon?: Maybe<Scalars['String']>;
  /** description */
  id?: Maybe<Scalars['ID']>;
  /** description */
  modified?: Maybe<Scalars['DateTime']>;
  /** description */
  name: Scalars['String'];
  /** description */
  order?: Maybe<Scalars['Int']>;
};

export type FeatureInput = {
  description?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
};

/** Field description */
export type Field = {
  __typename?: 'Field';
  /** description */
  created?: Maybe<Scalars['DateTime']>;
  /** description */
  cssClass?: Maybe<Scalars['String']>;
  /** description */
  customProperties?: Maybe<Scalars['String']>;
  /** description */
  defaultValue?: Maybe<Scalars['String']>;
  /** description */
  deleted?: Maybe<Scalars['DateTime']>;
  /** description */
  dependencyPattern?: Maybe<Scalars['String']>;
  /** description */
  description?: Maybe<Scalars['String']>;
  /** description */
  disabled?: Maybe<Scalars['Boolean']>;
  /** description */
  displayType?: Maybe<Scalars['String']>;
  /** description */
  id?: Maybe<Scalars['ID']>;
  /** description */
  isSystemLocked?: Maybe<Scalars['Boolean']>;
  /** description */
  items?: Maybe<Array<Maybe<Field>>>;
  /** description */
  label?: Maybe<Scalars['String']>;
  /** description */
  modified?: Maybe<Scalars['DateTime']>;
  /** description */
  name: Scalars['String'];
  /** description */
  orderNumber?: Maybe<Scalars['Int']>;
  /** description */
  parent?: Maybe<Field>;
  /** description */
  parentId?: Maybe<Scalars['ID']>;
  /** description */
  tip?: Maybe<Scalars['String']>;
  /** description */
  type?: Maybe<FieldType>;
  /** description */
  validation?: Maybe<Validation>;
  /** description */
  visible?: Maybe<Scalars['Boolean']>;
};

export type FieldInput = {
  cssClass?: InputMaybe<Scalars['String']>;
  customProperties?: InputMaybe<Scalars['String']>;
  defaultValue?: InputMaybe<Scalars['String']>;
  dependencyPattern?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  disabled?: InputMaybe<Scalars['Boolean']>;
  displayType?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  isSystemLocked?: InputMaybe<Scalars['Boolean']>;
  items?: InputMaybe<Array<InputMaybe<FieldInput>>>;
  label?: InputMaybe<Scalars['String']>;
  max?: InputMaybe<Scalars['Int']>;
  maxLength?: InputMaybe<Scalars['Int']>;
  min?: InputMaybe<Scalars['Int']>;
  minLength?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  orderNumber?: InputMaybe<Scalars['Int']>;
  parentId?: InputMaybe<Scalars['ID']>;
  pattern?: InputMaybe<Scalars['String']>;
  required?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<FieldType>;
  validation?: InputMaybe<FieldValidationInput>;
  /** Just a placeholder to support JSON format from the frontend. */
  value?: InputMaybe<Scalars['String']>;
  visible?: InputMaybe<Scalars['Boolean']>;
};

/** FieldSet description */
export type FieldSet = {
  __typename?: 'FieldSet';
  /** description */
  companyId?: Maybe<Scalars['ID']>;
  /** description */
  created?: Maybe<Scalars['DateTime']>;
  /** description */
  deleted?: Maybe<Scalars['DateTime']>;
  /** description */
  description?: Maybe<Scalars['String']>;
  /** description */
  id?: Maybe<Scalars['ID']>;
  /** description */
  isDefault?: Maybe<Scalars['Boolean']>;
  /** description */
  isSystemLocked?: Maybe<Scalars['Boolean']>;
  /** description */
  modified?: Maybe<Scalars['DateTime']>;
  /** description */
  name?: Maybe<Scalars['String']>;
  rootField?: Maybe<Field>;
  rootFieldId?: Maybe<Scalars['ID']>;
  /** description */
  title?: Maybe<Scalars['String']>;
};

export type FieldSetInput = {
  companyId?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  isSystemLocked?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  rootField?: InputMaybe<FieldInput>;
  rootFieldId?: InputMaybe<Scalars['ID']>;
  title?: InputMaybe<Scalars['String']>;
};

export enum FieldType {
  Array = 'ARRAY',
  Checkbox = 'CHECKBOX',
  Date = 'DATE',
  DatetimeLocal = 'DATETIME_LOCAL',
  Email = 'EMAIL',
  Fg = 'FG',
  File = 'FILE',
  Hidden = 'HIDDEN',
  Month = 'MONTH',
  Multiselect = 'MULTISELECT',
  Number = 'NUMBER',
  Option = 'OPTION',
  Password = 'PASSWORD',
  Radiobutton = 'RADIOBUTTON',
  Range = 'RANGE',
  Select = 'SELECT',
  Tel = 'TEL',
  Text = 'TEXT',
  Textarea = 'TEXTAREA',
  Time = 'TIME',
  Url = 'URL',
  Week = 'WEEK'
}

/** FieldValidation description */
export type FieldValidation = Validation & {
  __typename?: 'FieldValidation';
  /** description */
  dateFutureLimit?: Maybe<Scalars['Int']>;
  /** description */
  dateLimitStep?: Maybe<Scalars['String']>;
  /** description */
  datePastLimit?: Maybe<Scalars['Int']>;
  /** description */
  max?: Maybe<Scalars['Int']>;
  /** description */
  maxDate?: Maybe<Scalars['Int']>;
  /** description */
  maxLength?: Maybe<Scalars['Int']>;
  /** description */
  min?: Maybe<Scalars['Int']>;
  /** description */
  minDate?: Maybe<Scalars['Int']>;
  /** description */
  minLength?: Maybe<Scalars['Int']>;
  /** description */
  pattern?: Maybe<Scalars['String']>;
  /** description */
  required?: Maybe<Scalars['Boolean']>;
  /** description */
  step?: Maybe<Scalars['Int']>;
};

/** FieldValidationInput description */
export type FieldValidationInput = {
  /** description */
  dateFutureLimit?: InputMaybe<Scalars['Int']>;
  /** description */
  dateLimitStep?: InputMaybe<Scalars['String']>;
  /** description */
  datePastLimit?: InputMaybe<Scalars['Int']>;
  /** description */
  disabled?: InputMaybe<Scalars['Boolean']>;
  /** description */
  max?: InputMaybe<Scalars['Int']>;
  /** description */
  maxDate?: InputMaybe<Scalars['DateTime']>;
  /** description */
  maxLength?: InputMaybe<Scalars['Int']>;
  /** description */
  min?: InputMaybe<Scalars['Int']>;
  /** description */
  minDate?: InputMaybe<Scalars['DateTime']>;
  /** description */
  minLength?: InputMaybe<Scalars['Int']>;
  /** description */
  orderNumber?: InputMaybe<Scalars['Int']>;
  /** description */
  pattern?: InputMaybe<Scalars['String']>;
  /** description */
  required?: InputMaybe<Scalars['Boolean']>;
  /** description */
  step?: InputMaybe<Scalars['Int']>;
  /** description */
  visible?: InputMaybe<Scalars['Boolean']>;
};

export type Import = {
  __typename?: 'Import';
  checksEmpty?: Maybe<Scalars['Int']>;
  checksWithResults?: Maybe<Scalars['Int']>;
  created?: Maybe<Scalars['DateTime']>;
  databaseDate?: Maybe<Scalars['DateTime']>;
  databaseId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['DateTime']>;
  fileUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  individuals?: Maybe<Scalars['Int']>;
  modified?: Maybe<Scalars['DateTime']>;
  moreInfo?: Maybe<Scalars['String']>;
  organizations?: Maybe<Scalars['Int']>;
  remarks?: Maybe<Scalars['String']>;
  source?: Maybe<DatabaseSource>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['ID']>;
};

export type ImportInput = {
  fileUrl?: InputMaybe<Scalars['String']>;
  moreInfo?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<DatabaseSource>;
};

export type Invoice = {
  __typename?: 'Invoice';
  companyId?: Maybe<Scalars['ID']>;
  created?: Maybe<Scalars['DateTime']>;
  deleted?: Maybe<Scalars['DateTime']>;
  documentId?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  invoiceItems?: Maybe<Array<Maybe<InvoiceItem>>>;
  modified?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<TransactionStatus>;
  totalPrice?: Maybe<Scalars['Decimal']>;
  transactions?: Maybe<Array<Maybe<Transaction>>>;
};

export type InvoiceItem = {
  __typename?: 'InvoiceItem';
  created?: Maybe<Scalars['DateTime']>;
  deleted?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  invoice?: Maybe<Invoice>;
  invoiceId?: Maybe<Scalars['ID']>;
  modified?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Decimal']>;
  purchase?: Maybe<Purchase>;
  purchaseId?: Maybe<Scalars['ID']>;
  purchaseItem?: Maybe<PurchaseItem>;
  purchaseItemId?: Maybe<Scalars['ID']>;
  spentChecks?: Maybe<Scalars['Int']>;
};

/** Language description */
export type Language = {
  __typename?: 'Language';
  /** description */
  id: Scalars['Int'];
  /** description */
  isDefault: Scalars['Boolean'];
  /** description */
  isoCode2: Scalars['String'];
  /** description */
  isoCode3?: Maybe<Scalars['String']>;
  /** description */
  name: Scalars['String'];
};

export type LanguageInput = {
  id?: InputMaybe<Scalars['ID']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  isoCode2?: InputMaybe<Scalars['String']>;
  isoCode3?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptInvitation?: Maybe<User>;
  addCompanyNote?: Maybe<Company>;
  addPurchaseNote?: Maybe<Purchase>;
  createCompany?: Maybe<Company>;
  createCompanyCheck?: Maybe<CompanyCheck>;
  createCompanyToken?: Maybe<CompanyToken>;
  createConfigApplication?: Maybe<ConfigApplication>;
  createConfiguration?: Maybe<Configuration>;
  createCountry?: Maybe<Country>;
  createFeature?: Maybe<Feature>;
  createField?: Maybe<Field>;
  createFieldSet?: Maybe<FieldSet>;
  createImport?: Maybe<Import>;
  createLanguage?: Maybe<Language>;
  createProduct?: Maybe<Product>;
  createProductCheck?: Maybe<ProductCheck>;
  createProvider?: Maybe<Provider>;
  createProviderCheck?: Maybe<ProviderCheck>;
  createPurchase?: Maybe<Purchase>;
  createSession?: Maybe<Session>;
  createState?: Maybe<State>;
  createTag?: Maybe<Tag>;
  createTranslation?: Maybe<Translation>;
  createUpdateTranslations?: Maybe<Array<Maybe<Translation>>>;
  createUser?: Maybe<User>;
  deleteCompany?: Maybe<Company>;
  deleteCompanyCheck?: Maybe<CompanyCheck>;
  deleteCompanyToken?: Maybe<CompanyToken>;
  deleteConfigApplication?: Maybe<ConfigApplication>;
  deleteConfiguration?: Maybe<Configuration>;
  deleteCountry?: Maybe<Country>;
  deleteField?: Maybe<Field>;
  deleteFieldSet?: Maybe<FieldSet>;
  deleteLanguage?: Maybe<Language>;
  deleteProduct?: Maybe<Product>;
  deleteProductCheck?: Maybe<ProductCheck>;
  deleteProvider?: Maybe<Provider>;
  deleteProviderCheck?: Maybe<ProviderCheck>;
  deleteSession?: Maybe<Session>;
  deleteState?: Maybe<State>;
  deleteTag?: Maybe<Tag>;
  deleteTranslation?: Maybe<Translation>;
  exportTranslations?: Maybe<Scalars['Boolean']>;
  /** Generates authentication token for API based on token provided for company. Expiration time is 30 min. */
  getToken?: Maybe<Scalars['String']>;
  importBusinessData?: Maybe<Scalars['Boolean']>;
  importTranslations?: Maybe<Scalars['Boolean']>;
  invoiceDocument?: Maybe<Scalars['ID']>;
  lockUser?: Maybe<User>;
  loginUser?: Maybe<User>;
  payAgain?: Maybe<Scalars['String']>;
  preSessionCreateCheck?: Maybe<Scalars['String']>;
  recheckSession?: Maybe<Session>;
  register?: Maybe<User>;
  requestResetPasswordUser?: Maybe<Scalars['Boolean']>;
  resendInvite?: Maybe<User>;
  resendVerificationEndUser?: Maybe<User>;
  resetPasswordUser?: Maybe<Scalars['Boolean']>;
  restoreCompany?: Maybe<Company>;
  restoreCompanyCheck?: Maybe<CompanyCheck>;
  restoreCompanyToken?: Maybe<CompanyToken>;
  restoreConfigApplication?: Maybe<ConfigApplication>;
  restoreConfiguration?: Maybe<Configuration>;
  restoreCountry?: Maybe<Country>;
  restoreField?: Maybe<Field>;
  restoreFieldSet?: Maybe<FieldSet>;
  restoreLanguage?: Maybe<Language>;
  restoreProduct?: Maybe<Product>;
  restoreProductCheck?: Maybe<ProductCheck>;
  restoreProvider?: Maybe<Provider>;
  restoreProviderCheck?: Maybe<ProviderCheck>;
  restoreSession?: Maybe<Session>;
  restoreState?: Maybe<State>;
  restoreTag?: Maybe<Tag>;
  restoreTranslation?: Maybe<Translation>;
  /** Attach user to the new company - please be aware that the user will have same permissoins as for the current company. */
  setCompany?: Maybe<User>;
  setCompanyPaymentOptions?: Maybe<Company>;
  setCompanyStatus?: Maybe<Company>;
  setInvoiceStatus?: Maybe<Invoice>;
  setPermissions?: Maybe<User>;
  setPurchaseStatus?: Maybe<Purchase>;
  /** For admins - to set up other companies and see reportings */
  switchCompany?: Maybe<User>;
  switchUserRole?: Maybe<User>;
  /** Initiate enabling/disabling of monitoring. Returns session with optimistic result, but gives no info whether XRAY actually succeeded in setting monitoring status on provider-side. Requires SessionCancel permission. */
  toggleMonitoring?: Maybe<Session>;
  updateCheckResult?: Maybe<CheckResult>;
  updateCompany?: Maybe<Company>;
  updateCompanyCheck?: Maybe<CompanyCheck>;
  updateCompanyToken?: Maybe<CompanyToken>;
  updateConfigApplication?: Maybe<ConfigApplication>;
  updateConfiguration?: Maybe<Configuration>;
  updateCountry?: Maybe<Country>;
  updateFeature?: Maybe<Feature>;
  updateField?: Maybe<Field>;
  updateFieldSet?: Maybe<FieldSet>;
  updateLanguage?: Maybe<Language>;
  updateProduct?: Maybe<Product>;
  updateProductCheck?: Maybe<ProductCheck>;
  updateProvider?: Maybe<Provider>;
  updateProviderCheck?: Maybe<ProviderCheck>;
  updateState?: Maybe<State>;
  updateTag?: Maybe<Tag>;
  updateTranslation?: Maybe<Translation>;
  updateUser?: Maybe<User>;
  verifyEndUser?: Maybe<User>;
};


export type MutationAcceptInvitationArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationAddCompanyNoteArgs = {
  companyId: Scalars['ID'];
  note: Scalars['String'];
};


export type MutationAddPurchaseNoteArgs = {
  note: Scalars['String'];
  purchaseId: Scalars['ID'];
};


export type MutationCreateCompanyArgs = {
  item: CompanyInput;
};


export type MutationCreateCompanyCheckArgs = {
  item: CompanyCheckInput;
};


export type MutationCreateCompanyTokenArgs = {
  item: CompanyTokenInput;
};


export type MutationCreateConfigApplicationArgs = {
  item: ConfigApplicationInput;
};


export type MutationCreateConfigurationArgs = {
  item: ConfigurationInput;
};


export type MutationCreateCountryArgs = {
  item: CountryInput;
};


export type MutationCreateFeatureArgs = {
  item: FeatureInput;
};


export type MutationCreateFieldArgs = {
  item: FieldInput;
};


export type MutationCreateFieldSetArgs = {
  item: FieldSetInput;
};


export type MutationCreateImportArgs = {
  item: ImportInput;
};


export type MutationCreateLanguageArgs = {
  item: LanguageInput;
};


export type MutationCreateProductArgs = {
  item: ProductInput;
};


export type MutationCreateProductCheckArgs = {
  item: ProductCheckInput;
};


export type MutationCreateProviderArgs = {
  item: ProviderInput;
};


export type MutationCreateProviderCheckArgs = {
  item: ProviderCheckInput;
};


export type MutationCreatePurchaseArgs = {
  item: PurchaseInput;
};


export type MutationCreateSessionArgs = {
  item: SessionInput;
};


export type MutationCreateStateArgs = {
  item: StateInput;
};


export type MutationCreateTagArgs = {
  item: TagInput;
};


export type MutationCreateTranslationArgs = {
  item: TranslationInput;
};


export type MutationCreateUpdateTranslationsArgs = {
  items: Array<InputMaybe<TranslationInput>>;
};


export type MutationCreateUserArgs = {
  item: UserInput;
};


export type MutationDeleteCompanyArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationDeleteCompanyCheckArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationDeleteCompanyTokenArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationDeleteConfigApplicationArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationDeleteConfigurationArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationDeleteCountryArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationDeleteFieldArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationDeleteFieldSetArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationDeleteLanguageArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationDeleteProductArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationDeleteProductCheckArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationDeleteProviderArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationDeleteProviderCheckArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationDeleteSessionArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteStateArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationDeleteTagArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationDeleteTranslationArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationImportBusinessDataArgs = {
  configurationId: Scalars['ID'];
  description?: InputMaybe<Scalars['String']>;
  fileId?: InputMaybe<Scalars['ID']>;
};


export type MutationImportTranslationsArgs = {
  fileId?: InputMaybe<Scalars['ID']>;
};


export type MutationInvoiceDocumentArgs = {
  invoiceId?: InputMaybe<Scalars['ID']>;
};


export type MutationLockUserArgs = {
  lock: Scalars['Boolean'];
  userId: Scalars['ID'];
};


export type MutationLoginUserArgs = {
  password: Scalars['String'];
  username: Scalars['String'];
};


export type MutationPayAgainArgs = {
  invoiceId?: InputMaybe<Scalars['ID']>;
};


export type MutationPreSessionCreateCheckArgs = {
  configurationId?: InputMaybe<Scalars['ID']>;
};


export type MutationRecheckSessionArgs = {
  configurationId?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
};


export type MutationRegisterArgs = {
  registration: RegisterUserInput;
};


export type MutationRequestResetPasswordUserArgs = {
  username: Scalars['String'];
};


export type MutationResendInviteArgs = {
  userId: Scalars['ID'];
};


export type MutationResetPasswordUserArgs = {
  newPassword: Scalars['String'];
  username: Scalars['String'];
  verificationCode: Scalars['String'];
};


export type MutationRestoreCompanyArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationRestoreCompanyCheckArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationRestoreCompanyTokenArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationRestoreConfigApplicationArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationRestoreConfigurationArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationRestoreCountryArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationRestoreFieldArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationRestoreFieldSetArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationRestoreLanguageArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationRestoreProductArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationRestoreProductCheckArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationRestoreProviderArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationRestoreProviderCheckArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationRestoreSessionArgs = {
  id: Scalars['ID'];
};


export type MutationRestoreStateArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationRestoreTagArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationRestoreTranslationArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationSetCompanyArgs = {
  companyId: Scalars['ID'];
  isDefault: Scalars['Boolean'];
  join: Scalars['Boolean'];
  userId: Scalars['ID'];
};


export type MutationSetCompanyPaymentOptionsArgs = {
  allowedPaymentMethod?: InputMaybe<PaymentMethod>;
  companyId: Scalars['ID'];
  productId?: InputMaybe<Scalars['ID']>;
};


export type MutationSetCompanyStatusArgs = {
  adminMessage?: InputMaybe<Scalars['String']>;
  allowedPaymentMethod?: InputMaybe<PaymentMethod>;
  companyId: Scalars['ID'];
  newStatus: CompanyStatus;
  statusUserMessage?: InputMaybe<Scalars['String']>;
};


export type MutationSetInvoiceStatusArgs = {
  invoiceId?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<TransactionStatus>;
};


export type MutationSetPermissionsArgs = {
  allowed?: InputMaybe<Array<InputMaybe<Permission>>>;
  denied?: InputMaybe<Array<InputMaybe<Permission>>>;
  userId: Scalars['ID'];
};


export type MutationSetPurchaseStatusArgs = {
  id?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<PurchaseStatus>;
};


export type MutationSwitchCompanyArgs = {
  companyId: Scalars['ID'];
};


export type MutationSwitchUserRoleArgs = {
  toCompanyAdmin?: InputMaybe<Scalars['Boolean']>;
  userId: Scalars['ID'];
};


export type MutationToggleMonitoringArgs = {
  enable: Scalars['Boolean'];
  sessionId: Scalars['ID'];
};


export type MutationUpdateCheckResultArgs = {
  falsePositive: Scalars['Boolean'];
  id: Scalars['ID'];
  note: Scalars['String'];
};


export type MutationUpdateCompanyArgs = {
  item: CompanyInput;
};


export type MutationUpdateCompanyCheckArgs = {
  item: CompanyCheckInput;
};


export type MutationUpdateCompanyTokenArgs = {
  item: CompanyTokenInput;
};


export type MutationUpdateConfigApplicationArgs = {
  item: ConfigApplicationInput;
};


export type MutationUpdateConfigurationArgs = {
  item: ConfigurationInput;
};


export type MutationUpdateCountryArgs = {
  item: CountryInput;
};


export type MutationUpdateFeatureArgs = {
  item: FeatureInput;
};


export type MutationUpdateFieldArgs = {
  item: FieldInput;
};


export type MutationUpdateFieldSetArgs = {
  item: FieldSetInput;
};


export type MutationUpdateLanguageArgs = {
  item: LanguageInput;
};


export type MutationUpdateProductArgs = {
  item: ProductInput;
};


export type MutationUpdateProductCheckArgs = {
  item: ProductCheckInput;
};


export type MutationUpdateProviderArgs = {
  item: ProviderInput;
};


export type MutationUpdateProviderCheckArgs = {
  item: ProviderCheckInput;
};


export type MutationUpdateStateArgs = {
  item: StateInput;
};


export type MutationUpdateTagArgs = {
  item: TagInput;
};


export type MutationUpdateTranslationArgs = {
  item: TranslationInput;
};


export type MutationUpdateUserArgs = {
  item: UserInput;
};


export type MutationVerifyEndUserArgs = {
  email: Scalars['String'];
  verificationCode: Scalars['String'];
};

export type NumberValidation = Validation & {
  __typename?: 'NumberValidation';
  /** Allowed max for the field value. */
  max?: Maybe<Scalars['Int']>;
  /** Allowed min for the field value. */
  min?: Maybe<Scalars['Int']>;
  /** Is the field value required. */
  required?: Maybe<Scalars['Boolean']>;
  /** Required number step. */
  step?: Maybe<Scalars['Int']>;
};

/** Page request input type. Used for pagination and filtering large set of data */
export type PageRequestInput = {
  /** Filter by list of check statuses */
  checkStatuses?: InputMaybe<Array<InputMaybe<CheckStatus>>>;
  /** Filter by list of check types */
  checkTypes?: InputMaybe<Array<InputMaybe<CheckType>>>;
  /** Filter by list of company check identifiers */
  companyCheckIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Filter by list of company identifiers */
  companyIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Filter by list of company statuses */
  companyStatuses?: InputMaybe<Array<InputMaybe<CompanyStatus>>>;
  /** Filter by list of configuration identifiers */
  configurationIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Filter date range */
  dateRange?: InputMaybe<DateRangeInput>;
  /** Group results by specific StatsMetric value */
  grouping?: InputMaybe<StatsMetric>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isAvailable?: InputMaybe<Scalars['Boolean']>;
  /** Show sessions with business checks */
  isBusiness?: InputMaybe<Scalars['Boolean']>;
  /** Show sessions with bundled business with persons checks */
  isBusinessWithPersons?: InputMaybe<Scalars['Boolean']>;
  /** Show sessions with monitoring checks */
  isMonitoring?: InputMaybe<Scalars['Boolean']>;
  /** Show real sessions */
  isNonTesting?: InputMaybe<Scalars['Boolean']>;
  /** Show sessions whose check status is pending */
  isPendingCheck?: InputMaybe<Scalars['Boolean']>;
  /** Show sessions with personal checks */
  isPerson?: InputMaybe<Scalars['Boolean']>;
  /** Show test sessions */
  isTesting?: InputMaybe<Scalars['Boolean']>;
  isVisible?: InputMaybe<Scalars['Boolean']>;
  /** Filter by list of provider identifiers */
  providerIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A query that includes parameters for pagination, search, and filtering data */
  query?: InputMaybe<Scalars['String']>;
  /** Filter by list of reference identifiers */
  referenceIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Filter by list of session identifiers */
  sessionIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Filter by list of session statuses */
  sessionStatuses?: InputMaybe<Array<InputMaybe<SessionStatus>>>;
  /** Should deleted items be included in result. */
  showDeleted?: InputMaybe<Scalars['Boolean']>;
  /** How many elements to skip in result page */
  skip?: InputMaybe<Scalars['Int']>;
  /** Sorting policy - sort results by specific column */
  sortBy?: InputMaybe<Scalars['String']>;
  /** Sort order - ascending or descending */
  sortOrder?: InputMaybe<Scalars['String']>;
  /** Statistic date range */
  statsRange?: InputMaybe<DateRangeInput>;
  /** How many elements to include in a result page. */
  take?: InputMaybe<Scalars['Int']>;
  /** Filter by list of transaction statuses */
  transactionStatuses?: InputMaybe<Array<InputMaybe<TransactionStatus>>>;
};

/** This object type represents page that contains multiple items and is returned after executing queries with PageRequestInput parameters */
export type PaginatorCompanyCheckGType = {
  __typename?: 'PaginatorCompanyCheckGType';
  /** List that contains objects of the type CompanyCheckGType. Data returned satisfies requested parameters from PageRequestInput object */
  data?: Maybe<Array<Maybe<CompanyCheck>>>;
  /** Total count of elements that can be returned */
  totalCount?: Maybe<Scalars['Int']>;
};

/** This object type represents page that contains multiple items and is returned after executing queries with PageRequestInput parameters */
export type PaginatorCompanyGType = {
  __typename?: 'PaginatorCompanyGType';
  /** List that contains objects of the type CompanyGType. Data returned satisfies requested parameters from PageRequestInput object */
  data?: Maybe<Array<Maybe<Company>>>;
  /** Total count of elements that can be returned */
  totalCount?: Maybe<Scalars['Int']>;
};

/** This object type represents page that contains multiple items and is returned after executing queries with PageRequestInput parameters */
export type PaginatorCompanyTokenGType = {
  __typename?: 'PaginatorCompanyTokenGType';
  /** List that contains objects of the type CompanyTokenGType. Data returned satisfies requested parameters from PageRequestInput object */
  data?: Maybe<Array<Maybe<CompanyToken>>>;
  /** Total count of elements that can be returned */
  totalCount?: Maybe<Scalars['Int']>;
};

/** This object type represents page that contains multiple items and is returned after executing queries with PageRequestInput parameters */
export type PaginatorConfigApplicationGType = {
  __typename?: 'PaginatorConfigApplicationGType';
  /** List that contains objects of the type ConfigApplicationGType. Data returned satisfies requested parameters from PageRequestInput object */
  data?: Maybe<Array<Maybe<ConfigApplication>>>;
  /** Total count of elements that can be returned */
  totalCount?: Maybe<Scalars['Int']>;
};

/** This object type represents page that contains multiple items and is returned after executing queries with PageRequestInput parameters */
export type PaginatorConfigurationGType = {
  __typename?: 'PaginatorConfigurationGType';
  /** List that contains objects of the type ConfigurationGType. Data returned satisfies requested parameters from PageRequestInput object */
  data?: Maybe<Array<Maybe<Configuration>>>;
  /** Total count of elements that can be returned */
  totalCount?: Maybe<Scalars['Int']>;
};

/** This object type represents page that contains multiple items and is returned after executing queries with PageRequestInput parameters */
export type PaginatorCountryGType = {
  __typename?: 'PaginatorCountryGType';
  /** List that contains objects of the type CountryGType. Data returned satisfies requested parameters from PageRequestInput object */
  data?: Maybe<Array<Maybe<Country>>>;
  /** Total count of elements that can be returned */
  totalCount?: Maybe<Scalars['Int']>;
};

/** This object type represents page that contains multiple items and is returned after executing queries with PageRequestInput parameters */
export type PaginatorDashboardGType = {
  __typename?: 'PaginatorDashboardGType';
  /** List that contains objects of the type DashboardGType. Data returned satisfies requested parameters from PageRequestInput object */
  data?: Maybe<Array<Maybe<Dashboard>>>;
  /** Total count of elements that can be returned */
  totalCount?: Maybe<Scalars['Int']>;
};

/** This object type represents page that contains multiple items and is returned after executing queries with PageRequestInput parameters */
export type PaginatorFeatureGType = {
  __typename?: 'PaginatorFeatureGType';
  /** List that contains objects of the type FeatureGType. Data returned satisfies requested parameters from PageRequestInput object */
  data?: Maybe<Array<Maybe<Feature>>>;
  /** Total count of elements that can be returned */
  totalCount?: Maybe<Scalars['Int']>;
};

/** This object type represents page that contains multiple items and is returned after executing queries with PageRequestInput parameters */
export type PaginatorFieldGType = {
  __typename?: 'PaginatorFieldGType';
  /** List that contains objects of the type FieldGType. Data returned satisfies requested parameters from PageRequestInput object */
  data?: Maybe<Array<Maybe<Field>>>;
  /** Total count of elements that can be returned */
  totalCount?: Maybe<Scalars['Int']>;
};

/** This object type represents page that contains multiple items and is returned after executing queries with PageRequestInput parameters */
export type PaginatorFieldSetGType = {
  __typename?: 'PaginatorFieldSetGType';
  /** List that contains objects of the type FieldSetGType. Data returned satisfies requested parameters from PageRequestInput object */
  data?: Maybe<Array<Maybe<FieldSet>>>;
  /** Total count of elements that can be returned */
  totalCount?: Maybe<Scalars['Int']>;
};

export type PaginatorImportGType = {
  __typename?: 'PaginatorImportGType';
  data?: Maybe<Array<Maybe<Import>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

/** This object type represents page that contains multiple items and is returned after executing queries with PageRequestInput parameters */
export type PaginatorInvoiceGType = {
  __typename?: 'PaginatorInvoiceGType';
  /** List that contains objects of the type InvoiceGType. Data returned satisfies requested parameters from PageRequestInput object */
  data?: Maybe<Array<Maybe<Invoice>>>;
  /** Total count of elements that can be returned */
  totalCount?: Maybe<Scalars['Int']>;
};

/** This object type represents page that contains multiple items and is returned after executing queries with PageRequestInput parameters */
export type PaginatorLangGType = {
  __typename?: 'PaginatorLangGType';
  /** List that contains objects of the type LangGType. Data returned satisfies requested parameters from PageRequestInput object */
  data?: Maybe<Array<Maybe<Language>>>;
  /** Total count of elements that can be returned */
  totalCount?: Maybe<Scalars['Int']>;
};

/** This object type represents page that contains multiple items and is returned after executing queries with PageRequestInput parameters */
export type PaginatorPersonOrBusinessGType = {
  __typename?: 'PaginatorPersonOrBusinessGType';
  /** List that contains objects of the type PersonOrBusinessGType. Data returned satisfies requested parameters from PageRequestInput object */
  data?: Maybe<Array<Maybe<PersonOrBusiness>>>;
  /** Total count of elements that can be returned */
  totalCount?: Maybe<Scalars['Int']>;
};

/** This object type represents page that contains multiple items and is returned after executing queries with PageRequestInput parameters */
export type PaginatorProductCheckGType = {
  __typename?: 'PaginatorProductCheckGType';
  /** List that contains objects of the type ProductCheckGType. Data returned satisfies requested parameters from PageRequestInput object */
  data?: Maybe<Array<Maybe<ProductCheck>>>;
  /** Total count of elements that can be returned */
  totalCount?: Maybe<Scalars['Int']>;
};

/** This object type represents page that contains multiple items and is returned after executing queries with PageRequestInput parameters */
export type PaginatorProductGType = {
  __typename?: 'PaginatorProductGType';
  /** List that contains objects of the type ProductGType. Data returned satisfies requested parameters from PageRequestInput object */
  data?: Maybe<Array<Maybe<Product>>>;
  /** Total count of elements that can be returned */
  totalCount?: Maybe<Scalars['Int']>;
};

/** This object type represents page that contains multiple items and is returned after executing queries with PageRequestInput parameters */
export type PaginatorProviderCheckGType = {
  __typename?: 'PaginatorProviderCheckGType';
  /** List that contains objects of the type ProviderCheckGType. Data returned satisfies requested parameters from PageRequestInput object */
  data?: Maybe<Array<Maybe<ProviderCheck>>>;
  /** Total count of elements that can be returned */
  totalCount?: Maybe<Scalars['Int']>;
};

/** This object type represents page that contains multiple items and is returned after executing queries with PageRequestInput parameters */
export type PaginatorProviderGType = {
  __typename?: 'PaginatorProviderGType';
  /** List that contains objects of the type ProviderGType. Data returned satisfies requested parameters from PageRequestInput object */
  data?: Maybe<Array<Maybe<Provider>>>;
  /** Total count of elements that can be returned */
  totalCount?: Maybe<Scalars['Int']>;
};

/** This object type represents page that contains multiple items and is returned after executing queries with PageRequestInput parameters */
export type PaginatorPurchaseGType = {
  __typename?: 'PaginatorPurchaseGType';
  /** List that contains objects of the type PurchaseGType. Data returned satisfies requested parameters from PageRequestInput object */
  data?: Maybe<Array<Maybe<Purchase>>>;
  /** Total count of elements that can be returned */
  totalCount?: Maybe<Scalars['Int']>;
};

/** This object type represents page that contains multiple items and is returned after executing queries with PageRequestInput parameters */
export type PaginatorSessionGType = {
  __typename?: 'PaginatorSessionGType';
  /** List that contains objects of the type SessionGType. Data returned satisfies requested parameters from PageRequestInput object */
  data?: Maybe<Array<Maybe<Session>>>;
  /** Total count of elements that can be returned */
  totalCount?: Maybe<Scalars['Int']>;
};

/** This object type represents page that contains multiple items and is returned after executing queries with PageRequestInput parameters */
export type PaginatorStateGType = {
  __typename?: 'PaginatorStateGType';
  /** List that contains objects of the type StateGType. Data returned satisfies requested parameters from PageRequestInput object */
  data?: Maybe<Array<Maybe<State>>>;
  /** Total count of elements that can be returned */
  totalCount?: Maybe<Scalars['Int']>;
};

/** This object type represents page that contains multiple items and is returned after executing queries with PageRequestInput parameters */
export type PaginatorTagGType = {
  __typename?: 'PaginatorTagGType';
  /** List that contains objects of the type TagGType. Data returned satisfies requested parameters from PageRequestInput object */
  data?: Maybe<Array<Maybe<Tag>>>;
  /** Total count of elements that can be returned */
  totalCount?: Maybe<Scalars['Int']>;
};

/** This object type represents page that contains multiple items and is returned after executing queries with PageRequestInput parameters */
export type PaginatorTransGType = {
  __typename?: 'PaginatorTransGType';
  /** List that contains objects of the type TransGType. Data returned satisfies requested parameters from PageRequestInput object */
  data?: Maybe<Array<Maybe<Translation>>>;
  /** Total count of elements that can be returned */
  totalCount?: Maybe<Scalars['Int']>;
};

/** This object type represents page that contains multiple items and is returned after executing queries with PageRequestInput parameters */
export type PaginatorTransLangGType = {
  __typename?: 'PaginatorTransLangGType';
  /** List that contains objects of the type TransLangGType. Data returned satisfies requested parameters from PageRequestInput object */
  data?: Maybe<Array<Maybe<TranslationLanguage>>>;
  /** Total count of elements that can be returned */
  totalCount?: Maybe<Scalars['Int']>;
};

/** This object type represents page that contains multiple items and is returned after executing queries with PageRequestInput parameters */
export type PaginatorTransactionGType = {
  __typename?: 'PaginatorTransactionGType';
  /** List that contains objects of the type TransactionGType. Data returned satisfies requested parameters from PageRequestInput object */
  data?: Maybe<Array<Maybe<Transaction>>>;
  /** Total count of elements that can be returned */
  totalCount?: Maybe<Scalars['Int']>;
};

/** This object type represents page that contains multiple items and is returned after executing queries with PageRequestInput parameters */
export type PaginatorUserGType = {
  __typename?: 'PaginatorUserGType';
  /** List that contains objects of the type UserGType. Data returned satisfies requested parameters from PageRequestInput object */
  data?: Maybe<Array<Maybe<User>>>;
  /** Total count of elements that can be returned */
  totalCount?: Maybe<Scalars['Int']>;
};

/** PaymentInterval description */
export enum PaymentInterval {
  /** PerCheck - description */
  PerCheck = 'PER_CHECK',
  /** PerDay - description */
  PerDay = 'PER_DAY'
}

/** PaymentMethod description */
export enum PaymentMethod {
  /** Card - description */
  Card = 'CARD',
  /** Invoice - description */
  Invoice = 'INVOICE',
  /** None - description */
  None = 'NONE'
}

/** PaymentOption description */
export enum PaymentOption {
  /** Monthly - description */
  Monthly = 'MONTHLY',
  /** PayAsYouGo - description */
  PayAsYouGo = 'PAY_AS_YOU_GO'
}

/** Permission description */
export enum Permission {
  /** Admin - description */
  Admin = 'ADMIN',
  /** AllCompaniesManage - description */
  AllCompaniesManage = 'ALL_COMPANIES_MANAGE',
  /** AllCompaniesRead - description */
  AllCompaniesRead = 'ALL_COMPANIES_READ',
  /** APIAuthenticate - description */
  ApiAuthenticate = 'API_AUTHENTICATE',
  /** CheckResultFlagging - description */
  CheckResultFlagging = 'CHECK_RESULT_FLAGGING',
  /** CheckResultRead - description */
  CheckResultRead = 'CHECK_RESULT_READ',
  /** CompanyCheckManage - description */
  CompanyCheckManage = 'COMPANY_CHECK_MANAGE',
  /** CompanyCheckPricingManage - description */
  CompanyCheckPricingManage = 'COMPANY_CHECK_PRICING_MANAGE',
  /** CompanyCheckPricingRead - description */
  CompanyCheckPricingRead = 'COMPANY_CHECK_PRICING_READ',
  /** CompanyCheckRead - description */
  CompanyCheckRead = 'COMPANY_CHECK_READ',
  /** CompanyManage - description */
  CompanyManage = 'COMPANY_MANAGE',
  /** CompanyRead - description */
  CompanyRead = 'COMPANY_READ',
  /** ConfigurationManage - description */
  ConfigurationManage = 'CONFIGURATION_MANAGE',
  /** ConfigurationPricingManage - description */
  ConfigurationPricingManage = 'CONFIGURATION_PRICING_MANAGE',
  /** ConfigurationPricingRead - description */
  ConfigurationPricingRead = 'CONFIGURATION_PRICING_READ',
  /** ConfigurationRead - description */
  ConfigurationRead = 'CONFIGURATION_READ',
  /** InvoiceManage - Setting up invoice's status */
  InvoiceManage = 'INVOICE_MANAGE',
  /** Manager - description */
  Manager = 'MANAGER',
  /** ProductManage - description */
  ProductManage = 'PRODUCT_MANAGE',
  /** ProductRead - description */
  ProductRead = 'PRODUCT_READ',
  /** ProviderManage - description */
  ProviderManage = 'PROVIDER_MANAGE',
  /** ProviderPricingManage - description */
  ProviderPricingManage = 'PROVIDER_PRICING_MANAGE',
  /** ProviderPricingRead - description */
  ProviderPricingRead = 'PROVIDER_PRICING_READ',
  /** ProviderRead - description */
  ProviderRead = 'PROVIDER_READ',
  /** PurchaseManage - description */
  PurchaseManage = 'PURCHASE_MANAGE',
  /** PurchaseRead - description */
  PurchaseRead = 'PURCHASE_READ',
  /** ReportingAggregatedRead - description */
  ReportingAggregatedRead = 'REPORTING_AGGREGATED_READ',
  /** ReportingRead - description */
  ReportingRead = 'REPORTING_READ',
  /** SessionCancel - description */
  SessionCancel = 'SESSION_CANCEL',
  /** SessionCreate - description */
  SessionCreate = 'SESSION_CREATE',
  /** SessionRead - description */
  SessionRead = 'SESSION_READ',
  /** SessionCreate - description */
  SessionTestCreate = 'SESSION_TEST_CREATE',
  /** SystemManage - description */
  SystemManage = 'SYSTEM_MANAGE',
  /** SystemRead - description */
  SystemRead = 'SYSTEM_READ',
  /** User - description */
  User = 'USER',
  /** UserManage - description */
  UserManage = 'USER_MANAGE',
  /** UserRead - description */
  UserRead = 'USER_READ',
  /** XRayProviderManage - description */
  XRayProviderManage = 'X_RAY_PROVIDER_MANAGE',
  /** XRayProviderRead - description */
  XRayProviderRead = 'X_RAY_PROVIDER_READ'
}

/** Person object type contains data related to person that is used during session processing and checking */
export type Person = {
  __typename?: 'Person';
  /** List of all addresses associated with this person */
  addresses?: Maybe<Array<Maybe<Address>>>;
  /** List of all businesses associated with this person */
  businesses?: Maybe<Array<Maybe<Business>>>;
  /** List of all contact information for this person */
  communications?: Maybe<Array<Maybe<Communication>>>;
  /** Date and time when person is first entered into the system */
  created?: Maybe<Scalars['DateTime']>;
  /** Date and time when person is deleted from the system */
  deleted?: Maybe<Scalars['DateTime']>;
  /** Person's date of birth */
  dob?: Maybe<Scalars['DateTime']>;
  /** List of all registered documents that belong to this person */
  documents?: Maybe<Array<Maybe<Document>>>;
  /** Person's first name */
  firstName?: Maybe<Scalars['String']>;
  /** First session where checks for this person were performed on */
  firstSession?: Maybe<Session>;
  /** Person's gender */
  gender?: Maybe<Scalars['String']>;
  /** Unique ID of the person */
  id?: Maybe<Scalars['ID']>;
  /** Person's name written in latin-1 character set, also called ISO-8859-1 */
  isoLatin1Name?: Maybe<Scalars['String']>;
  /** Person's last name */
  lastName?: Maybe<Scalars['String']>;
  /** Last session where checks for this person were performed on */
  lastSession?: Maybe<Session>;
  /** Person's middle name */
  middleName?: Maybe<Scalars['String']>;
  /** Date and time when person is last modified */
  modified?: Maybe<Scalars['DateTime']>;
  /** Person's nationality */
  nationality?: Maybe<Scalars['String']>;
  /** Number of sessions that checked this person */
  numberOfSessions?: Maybe<Scalars['Int']>;
  /** Percentage of person's ownership */
  ownerPercentage?: Maybe<Scalars['Int']>;
  /** Unique ID of external referenced object */
  referenceId?: Maybe<Scalars['String']>;
  /** Name of the external referenced object */
  referenceName?: Maybe<Scalars['String']>;
  /** Person's role in related company */
  role?: Maybe<Scalars['String']>;
  /** Current session where checks for this person are being performed */
  session?: Maybe<Session>;
  /** Person's social number */
  socialNumber?: Maybe<Scalars['String']>;
  /** This field contains person's title which designates rank, office, or nobility; initials for an academic degree (MBA, Dr), a roman numeral used with a surname or other phrases associated with a name */
  title?: Maybe<Scalars['String']>;
};

/** Input object type used for passing objects with data related to particular person */
export type PersonInput = {
  /** List of addresses for the person. */
  addresses?: InputMaybe<Array<InputMaybe<AddressInput>>>;
  /** List of input objects with contact information for the person */
  communications?: InputMaybe<Array<InputMaybe<CommunicationInput>>>;
  /** Date of birth */
  dob?: InputMaybe<Scalars['DateTime']>;
  /** List of documents for the person */
  documents?: InputMaybe<Array<InputMaybe<DocumentInput>>>;
  /** Person's first name */
  firstName?: InputMaybe<Scalars['String']>;
  /** Person's gender */
  gender?: InputMaybe<Scalars['String']>;
  /** Unique ID of person */
  id?: InputMaybe<Scalars['ID']>;
  /** Person's name represented in Latin-1 character set, also called ISO-8859-1 */
  isoLatin1Name?: InputMaybe<Scalars['String']>;
  /** Person's last name */
  lastName?: InputMaybe<Scalars['String']>;
  /** Person's middle name */
  middleName?: InputMaybe<Scalars['String']>;
  /** Person's nationality */
  nationality?: InputMaybe<Scalars['String']>;
  /** Person's ownership percentage in company */
  ownerPercentage?: InputMaybe<Scalars['Int']>;
  /** Unique ID of external referenced object */
  referenceId?: InputMaybe<Scalars['String']>;
  /** Person's role in related company */
  role?: InputMaybe<Scalars['String']>;
  /** Person's social number */
  socialNumber?: InputMaybe<Scalars['String']>;
  /** This field contains person's title which designates rank, office, or nobility; initials for an academic degree (MBA, Dr), a roman numeral used with a surname or other phrases associated with a name */
  title?: InputMaybe<Scalars['String']>;
};

export type PersonOrBusiness = Business | Person;

/** Product description */
export type Product = {
  __typename?: 'Product';
  /** description */
  alternateDescription?: Maybe<Scalars['String']>;
  /** description */
  checks?: Maybe<Array<Maybe<ProductCheck>>>;
  /** description */
  coverId?: Maybe<Scalars['ID']>;
  /** description */
  created?: Maybe<Scalars['DateTime']>;
  /** description */
  createdBy?: Maybe<User>;
  /** description */
  createdById?: Maybe<Scalars['ID']>;
  /** description */
  deleted?: Maybe<Scalars['DateTime']>;
  /** description */
  description?: Maybe<Scalars['String']>;
  /** description */
  features?: Maybe<Array<Maybe<Feature>>>;
  /** description */
  icon?: Maybe<Scalars['String']>;
  /** description */
  id?: Maybe<Scalars['ID']>;
  /** description */
  isDefault?: Maybe<Scalars['Boolean']>;
  /** description */
  isDisabled?: Maybe<Scalars['Boolean']>;
  /** description */
  isVisible?: Maybe<Scalars['Boolean']>;
  /** description */
  modified?: Maybe<Scalars['DateTime']>;
  /** description */
  montlyFee?: Maybe<Scalars['Decimal']>;
  /** description */
  name: Scalars['String'];
  /** description */
  oldPrice?: Maybe<Scalars['Decimal']>;
  /** description */
  order?: Maybe<Scalars['Int']>;
  /** description */
  paymentOption?: Maybe<PaymentOption>;
  /** description */
  price?: Maybe<Scalars['Decimal']>;
  /** description */
  productChecks?: Maybe<Array<Maybe<ProductCheck>>>;
};


/** Product description */
export type ProductChecksArgs = {
  showDeleted?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


/** Product description */
export type ProductFeaturesArgs = {
  showDeleted?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

/** ProductCheck description */
export type ProductCheck = {
  __typename?: 'ProductCheck';
  /** description */
  created?: Maybe<Scalars['DateTime']>;
  /** description */
  createdBy?: Maybe<User>;
  /** description */
  createdById?: Maybe<Scalars['ID']>;
  /** description */
  deleted?: Maybe<Scalars['DateTime']>;
  /** description */
  description?: Maybe<Scalars['String']>;
  /** description */
  icon?: Maybe<Scalars['String']>;
  /** description */
  id?: Maybe<Scalars['ID']>;
  /** description */
  includedChecks?: Maybe<Scalars['Int']>;
  /** description */
  modified?: Maybe<Scalars['DateTime']>;
  /** description */
  name: Scalars['String'];
  /** description */
  nextPrice?: Maybe<Scalars['Decimal']>;
  /** description */
  order?: Maybe<Scalars['Int']>;
  /** description */
  paymentInterval?: Maybe<PaymentInterval>;
  /** description */
  product?: Maybe<Product>;
  /** description */
  productId?: Maybe<Scalars['ID']>;
  /** description */
  providerCheck?: Maybe<ProviderCheck>;
  /** description */
  providerCheckId?: Maybe<Scalars['ID']>;
};

export type ProductCheckInput = {
  description?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  includedChecks?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  nextPrice?: InputMaybe<Scalars['Decimal']>;
  order?: InputMaybe<Scalars['Int']>;
  paymentInterval?: InputMaybe<PaymentInterval>;
  productId?: InputMaybe<Scalars['ID']>;
  providerCheckId?: InputMaybe<Scalars['ID']>;
};

export type ProductInput = {
  alternateDescription?: InputMaybe<Scalars['String']>;
  coverId?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  features?: InputMaybe<Array<InputMaybe<FeatureInput>>>;
  icon?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  isDisabled?: InputMaybe<Scalars['Boolean']>;
  isVisible?: InputMaybe<Scalars['Boolean']>;
  montlyFee?: InputMaybe<Scalars['Decimal']>;
  name: Scalars['String'];
  oldPrice?: InputMaybe<Scalars['Decimal']>;
  order?: InputMaybe<Scalars['Int']>;
  paymentOption?: InputMaybe<PaymentOption>;
  price?: InputMaybe<Scalars['Decimal']>;
  productChecks?: InputMaybe<Array<InputMaybe<ProductCheckInput>>>;
};

/** Provider description */
export type Provider = {
  __typename?: 'Provider';
  /** description */
  code?: Maybe<Scalars['String']>;
  /** description */
  created?: Maybe<Scalars['DateTime']>;
  /** description */
  deleted?: Maybe<Scalars['DateTime']>;
  /** description */
  description?: Maybe<Scalars['String']>;
  /** description */
  id?: Maybe<Scalars['ID']>;
  /** description */
  isTesting?: Maybe<Scalars['Boolean']>;
  /** description */
  keyVaultCert?: Maybe<Scalars['String']>;
  /** description */
  keyVaultId?: Maybe<Scalars['String']>;
  /** description */
  modified?: Maybe<Scalars['DateTime']>;
  /** description */
  name?: Maybe<Scalars['String']>;
  /** description */
  testingAccessConfig?: Maybe<Scalars['String']>;
};

/** ProviderCheck description */
export type ProviderCheck = {
  __typename?: 'ProviderCheck';
  /** description */
  checkStrategy?: Maybe<CheckStrategy>;
  /** Check Type (i.e. ) */
  checkType?: Maybe<CheckType>;
  /** description */
  checkTypes?: Maybe<Array<Maybe<ProviderCheckType>>>;
  /** description */
  costPerCheck?: Maybe<Scalars['Decimal']>;
  /** Check country */
  country?: Maybe<Scalars['String']>;
  /** description */
  created?: Maybe<Scalars['DateTime']>;
  /** description */
  deleted?: Maybe<Scalars['DateTime']>;
  /** description */
  description?: Maybe<Scalars['String']>;
  /** description */
  fieldSet?: Maybe<FieldSet>;
  /** description */
  fieldSetId?: Maybe<Scalars['ID']>;
  /** description */
  id?: Maybe<Scalars['ID']>;
  /** description */
  matchingRules?: Maybe<Scalars['String']>;
  /** description */
  maxRateMonthly?: Maybe<Scalars['Int']>;
  /** description */
  modified?: Maybe<Scalars['DateTime']>;
  /** description */
  name?: Maybe<Scalars['String']>;
  /** description */
  pricePerCheck?: Maybe<Scalars['Decimal']>;
  /** description */
  provider?: Maybe<Provider>;
  /** description */
  providerId?: Maybe<Scalars['ID']>;
  /** description */
  revShare?: Maybe<Scalars['Decimal']>;
  /** description */
  sourceType?: Maybe<SourceType>;
};

export type ProviderCheckInput = {
  checkStrategy?: InputMaybe<CheckStrategy>;
  /** Main check type - check type carrier */
  checkType?: InputMaybe<CheckType>;
  checkTypes?: InputMaybe<Array<InputMaybe<ProviderCheckTypeInput>>>;
  costPerCheck?: InputMaybe<Scalars['Decimal']>;
  country?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  fieldSetId?: InputMaybe<Scalars['ID']>;
  fields?: InputMaybe<Array<InputMaybe<FieldInput>>>;
  id?: InputMaybe<Scalars['ID']>;
  matchingRules?: InputMaybe<Scalars['String']>;
  maxRateMonthly?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  pricePerCheck?: InputMaybe<Scalars['Decimal']>;
  providerId?: InputMaybe<Scalars['ID']>;
  revShare?: InputMaybe<Scalars['Decimal']>;
  sourceType?: InputMaybe<SourceType>;
};

/** ProviderCheckType description */
export type ProviderCheckType = {
  __typename?: 'ProviderCheckType';
  /** description */
  checkType?: Maybe<CheckType>;
  /** description */
  created?: Maybe<Scalars['DateTime']>;
  /** description */
  id?: Maybe<Scalars['ID']>;
  /** description */
  modified?: Maybe<Scalars['DateTime']>;
  /** description */
  providerCheck?: Maybe<ProviderCheck>;
  /** description */
  providerCheckId?: Maybe<Scalars['ID']>;
};

export type ProviderCheckTypeInput = {
  checkType?: InputMaybe<CheckType>;
  id?: InputMaybe<Scalars['ID']>;
  providerCheckId?: InputMaybe<Scalars['ID']>;
};

export type ProviderInput = {
  code?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  isTesting?: InputMaybe<Scalars['Boolean']>;
  keyVaultCert?: InputMaybe<Scalars['String']>;
  keyVaultId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  testingAccessConfig?: InputMaybe<Scalars['String']>;
};

/** Purchase description */
export type Purchase = {
  __typename?: 'Purchase';
  additionalCharges?: Maybe<Array<Maybe<Invoice>>>;
  /** description */
  company?: Maybe<Company>;
  /** description */
  companyId?: Maybe<Scalars['ID']>;
  /** description */
  created?: Maybe<Scalars['DateTime']>;
  /** description */
  createdBy?: Maybe<User>;
  /** description */
  createdById?: Maybe<Scalars['ID']>;
  /** description */
  deleted?: Maybe<Scalars['DateTime']>;
  /** description */
  endOfSubscription?: Maybe<Scalars['DateTime']>;
  /** description */
  id?: Maybe<Scalars['ID']>;
  /** description */
  isActive?: Maybe<Scalars['Boolean']>;
  /** description */
  isInSubscription?: Maybe<Scalars['Boolean']>;
  /** description */
  maxRateMonthly?: Maybe<Scalars['Int']>;
  /** description */
  modified?: Maybe<Scalars['DateTime']>;
  /** Permission: ALL_COMPANIES_MANAGE */
  note?: Maybe<Scalars['String']>;
  /** description */
  paymentMethod?: Maybe<PaymentMethod>;
  /** description */
  price?: Maybe<Scalars['Decimal']>;
  /** description */
  product?: Maybe<Product>;
  /** description */
  productId?: Maybe<Scalars['ID']>;
  productName: Scalars['String'];
  /** description */
  purchaseItems?: Maybe<Array<Maybe<PurchaseItem>>>;
  /** description */
  startOfNextSubscription?: Maybe<Scalars['DateTime']>;
  /** description */
  startOfSubscription?: Maybe<Scalars['DateTime']>;
  /** description */
  status?: Maybe<PurchaseStatus>;
};

export type PurchaseInput = {
  note?: InputMaybe<Scalars['String']>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  productId?: InputMaybe<Scalars['ID']>;
};

/** PurchaseItem description */
export type PurchaseItem = {
  __typename?: 'PurchaseItem';
  /** description */
  companyCheck?: Maybe<CompanyCheck>;
  /** description */
  companyCheckId?: Maybe<Scalars['ID']>;
  /** description */
  created?: Maybe<Scalars['DateTime']>;
  /** description */
  createdBy?: Maybe<User>;
  /** description */
  createdById?: Maybe<Scalars['ID']>;
  /** description */
  days?: Maybe<Scalars['Int']>;
  /** description */
  deleted?: Maybe<Scalars['DateTime']>;
  /** description */
  id?: Maybe<Scalars['ID']>;
  /** description */
  includedChecks?: Maybe<Scalars['Int']>;
  /** description */
  modified?: Maybe<Scalars['DateTime']>;
  /** description */
  price?: Maybe<Scalars['Decimal']>;
  /** description */
  productCheck?: Maybe<ProductCheck>;
  /** description */
  productCheckId?: Maybe<Scalars['ID']>;
  /** description */
  purchase?: Maybe<Purchase>;
  /** description */
  purchaseId?: Maybe<Scalars['ID']>;
  /** description */
  spentChecks?: Maybe<Scalars['Int']>;
};

/** PurchaseStatus description */
export enum PurchaseStatus {
  /** Authorized - description */
  Authorized = 'AUTHORIZED',
  /** Canceled - description */
  Canceled = 'CANCELED',
  /** Charged - description */
  Charged = 'CHARGED',
  /** Failed - description */
  Failed = 'FAILED',
  /** Initiated - description */
  Initiated = 'INITIATED',
  /** Invoicing - description */
  Invoicing = 'INVOICING',
  /** Pending - description */
  Pending = 'PENDING',
  /** Refunded - description */
  Refunded = 'REFUNDED',
  /** Voided - description */
  Voided = 'VOIDED'
}

export type Query = {
  __typename?: 'Query';
  companies?: Maybe<PaginatorCompanyGType>;
  company?: Maybe<Company>;
  companyCheck?: Maybe<CompanyCheck>;
  companyChecks?: Maybe<PaginatorCompanyCheckGType>;
  companyToken?: Maybe<CompanyToken>;
  companyTokenDL?: Maybe<CompanyToken>;
  companyTokens?: Maybe<PaginatorCompanyTokenGType>;
  configApplication?: Maybe<ConfigApplication>;
  configApplications?: Maybe<PaginatorConfigApplicationGType>;
  configuration?: Maybe<Configuration>;
  configurationDL?: Maybe<Configuration>;
  configurationOnCreateSession?: Maybe<Configuration>;
  configurations?: Maybe<PaginatorConfigurationGType>;
  countries?: Maybe<PaginatorCountryGType>;
  country?: Maybe<Country>;
  creditCard?: Maybe<Scalars['String']>;
  currentUser?: Maybe<User>;
  dashboards?: Maybe<PaginatorDashboardGType>;
  feature?: Maybe<Feature>;
  features?: Maybe<PaginatorFeatureGType>;
  field?: Maybe<Field>;
  fieldSet?: Maybe<FieldSet>;
  fieldSets?: Maybe<PaginatorFieldSetGType>;
  fields?: Maybe<PaginatorFieldGType>;
  import?: Maybe<Import>;
  imports?: Maybe<PaginatorImportGType>;
  inventories?: Maybe<PaginatorPersonOrBusinessGType>;
  invoice?: Maybe<Invoice>;
  invoices?: Maybe<PaginatorInvoiceGType>;
  language?: Maybe<Language>;
  languages?: Maybe<PaginatorLangGType>;
  product?: Maybe<Product>;
  productCheck?: Maybe<ProductCheck>;
  productChecks?: Maybe<PaginatorProductCheckGType>;
  products?: Maybe<PaginatorProductGType>;
  provider?: Maybe<Provider>;
  providerCheck?: Maybe<ProviderCheck>;
  providerChecks?: Maybe<PaginatorProviderCheckGType>;
  providers?: Maybe<PaginatorProviderGType>;
  purchase?: Maybe<Purchase>;
  purchases?: Maybe<PaginatorPurchaseGType>;
  sanctions?: Maybe<Array<Maybe<Sanction>>>;
  session?: Maybe<Session>;
  sessionCertificate?: Maybe<Session>;
  sessions?: Maybe<PaginatorSessionGType>;
  stasById?: Maybe<Stats>;
  state?: Maybe<State>;
  states?: Maybe<PaginatorStateGType>;
  stats?: Maybe<Array<Maybe<Stats>>>;
  /** Returns list of countries and document types they support, for given configuration. Currently, only returns data for government identity check. */
  supportedIdentityCheckTypes?: Maybe<Array<Maybe<SupportedCountry>>>;
  tag?: Maybe<Tag>;
  tags?: Maybe<PaginatorTagGType>;
  /**
   * Query for fetching info about predefined test entities.
   * @deprecated Query is not suitable for field configurations. Use testEntitiesCombined query instead.
   */
  testEntities?: Maybe<Array<Maybe<TestEntityGType>>>;
  /** Query for fetching info about predefined test entities. */
  testEntitiesCombined?: Maybe<Array<Maybe<CombinedTestEntityGType>>>;
  tokenUsage?: Maybe<Scalars['Int']>;
  transaction?: Maybe<Transaction>;
  transactions?: Maybe<PaginatorTransactionGType>;
  translation?: Maybe<Translation>;
  translations?: Maybe<PaginatorTransLangGType>;
  translationsByKey?: Maybe<Array<Maybe<Translation>>>;
  translationsByLanguage?: Maybe<Array<Maybe<Translation>>>;
  translationsFileId?: Maybe<Scalars['ID']>;
  translationsPlain?: Maybe<PaginatorTransGType>;
  user?: Maybe<User>;
  userDL?: Maybe<User>;
  users?: Maybe<PaginatorUserGType>;
};


export type QueryCompaniesArgs = {
  pageRequestInput?: InputMaybe<PageRequestInput>;
};


export type QueryCompanyArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryCompanyCheckArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryCompanyChecksArgs = {
  pageRequestInput?: InputMaybe<PageRequestInput>;
};


export type QueryCompanyTokenArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryCompanyTokenDlArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryCompanyTokensArgs = {
  pageRequestInput?: InputMaybe<PageRequestInput>;
};


export type QueryConfigApplicationArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryConfigApplicationsArgs = {
  pageRequestInput?: InputMaybe<PageRequestInput>;
};


export type QueryConfigurationArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryConfigurationDlArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryConfigurationOnCreateSessionArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryConfigurationsArgs = {
  pageRequestInput?: InputMaybe<PageRequestInput>;
};


export type QueryCountriesArgs = {
  pageRequestInput?: InputMaybe<PageRequestInput>;
};


export type QueryCountryArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryDashboardsArgs = {
  pageRequestInput?: InputMaybe<PageRequestInput>;
};


export type QueryFeatureArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryFeaturesArgs = {
  pageRequestInput?: InputMaybe<PageRequestInput>;
};


export type QueryFieldArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryFieldSetArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryFieldSetsArgs = {
  pageRequestInput?: InputMaybe<PageRequestInput>;
};


export type QueryFieldsArgs = {
  pageRequestInput?: InputMaybe<PageRequestInput>;
};


export type QueryImportArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryImportsArgs = {
  pageRequestInput?: InputMaybe<PageRequestInput>;
};


export type QueryInventoriesArgs = {
  pageRequestInput?: InputMaybe<PageRequestInput>;
};


export type QueryInvoiceArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryInvoicesArgs = {
  pageRequestInput?: InputMaybe<PageRequestInput>;
};


export type QueryLanguageArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryLanguagesArgs = {
  pageRequestInput?: InputMaybe<PageRequestInput>;
};


export type QueryProductArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryProductCheckArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryProductChecksArgs = {
  pageRequestInput?: InputMaybe<PageRequestInput>;
};


export type QueryProductsArgs = {
  pageRequestInput?: InputMaybe<PageRequestInput>;
};


export type QueryProviderArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryProviderCheckArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryProviderChecksArgs = {
  pageRequestInput?: InputMaybe<PageRequestInput>;
};


export type QueryProvidersArgs = {
  pageRequestInput?: InputMaybe<PageRequestInput>;
};


export type QueryPurchaseArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryPurchasesArgs = {
  pageRequestInput?: InputMaybe<PageRequestInput>;
};


export type QuerySanctionsArgs = {
  address?: InputMaybe<Scalars['String']>;
  andPolicy?: InputMaybe<Scalars['Boolean']>;
  country?: InputMaybe<Scalars['String']>;
  docNumber?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
};


export type QuerySessionArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QuerySessionCertificateArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QuerySessionsArgs = {
  pageRequestInput?: InputMaybe<PageRequestInput>;
};


export type QueryStasByIdArgs = {
  companyCheckId?: InputMaybe<Scalars['ID']>;
  companyId?: InputMaybe<Scalars['ID']>;
  configurationId?: InputMaybe<Scalars['ID']>;
  pageRequestInput?: InputMaybe<PageRequestInput>;
};


export type QueryStateArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryStatesArgs = {
  pageRequestInput?: InputMaybe<PageRequestInput>;
};


export type QueryStatsArgs = {
  pageRequestInput?: InputMaybe<PageRequestInput>;
};


export type QuerySupportedIdentityCheckTypesArgs = {
  configurationId?: InputMaybe<Scalars['ID']>;
};


export type QueryTagArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryTagsArgs = {
  pageRequestInput?: InputMaybe<PageRequestInput>;
};


export type QueryTestEntitiesArgs = {
  checkType?: InputMaybe<CheckType>;
};


export type QueryTestEntitiesCombinedArgs = {
  checkType?: InputMaybe<CheckType>;
};


export type QueryTokenUsageArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryTransactionArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryTransactionsArgs = {
  pageRequestInput?: InputMaybe<PageRequestInput>;
};


export type QueryTranslationArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryTranslationsArgs = {
  pageRequestInput?: InputMaybe<PageRequestInput>;
};


export type QueryTranslationsByKeyArgs = {
  key: Scalars['String'];
};


export type QueryTranslationsByLanguageArgs = {
  isoCode2?: InputMaybe<Scalars['String']>;
};


export type QueryTranslationsPlainArgs = {
  pageRequestInput?: InputMaybe<PageRequestInput>;
};


export type QueryUserArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryUserDlArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryUsersArgs = {
  pageRequestInput?: InputMaybe<PageRequestInput>;
};

export type RegisterUserInput = {
  address: Scalars['String'];
  address2?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  companyId: Scalars['String'];
  companyName: Scalars['String'];
  countryId?: InputMaybe<Scalars['ID']>;
  iAgree?: InputMaybe<Scalars['Boolean']>;
  userEmail: Scalars['String'];
  userFirstName: Scalars['String'];
  userLastName: Scalars['String'];
  userPassword: Scalars['String'];
  userPhone: Scalars['String'];
  vatNumber: Scalars['String'];
  website: Scalars['String'];
  zip: Scalars['String'];
};

/** Enumeration type that represents supported ways of sending check results back to user after session processing is done */
export enum ResponseTransport {
  /** If session has this response transport type then its check results will be sent using Azure Service Bus cloud messaging system */
  AzureServiceBus = 'AZURE_SERVICE_BUS',
  /** Session response with check results won't be sent. This type is used for development */
  DevNull = 'DEV_NULL',
  /** If session has this response transport type then its check results will be sent to pre-configured email address */
  Email = 'EMAIL',
  /** If session has this response transport type then its check results will be placed inside response body of HTTP long polling request sent by client */
  LongPolling = 'LONG_POLLING',
  /** If session has this response transport type then its check results will be sent using RabbitMQ messaging system */
  RabbitMq = 'RABBIT_MQ',
  /** If session has this response transport type then its check results will be sent using Redis publisher-subscriber messaging system */
  RedisPubSub = 'REDIS_PUB_SUB',
  /** If session has this response transport type then its check results will be sent over pre-configured webhook */
  Webhook = 'WEBHOOK'
}

export type Sanction = {
  __typename?: 'Sanction';
  addresses?: Maybe<Array<Maybe<SanctionAddress>>>;
  alts?: Maybe<Array<Maybe<SanctionAlt>>>;
  births?: Maybe<Array<Maybe<SanctionBirth>>>;
  country?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  databaseId?: Maybe<Scalars['String']>;
  docs?: Maybe<Array<Maybe<SanctionDoc>>>;
  id?: Maybe<Scalars['ID']>;
  modified?: Maybe<Scalars['DateTime']>;
  moreInfo?: Maybe<Scalars['String']>;
  moreInfoUrl?: Maybe<Scalars['String']>;
  registered?: Maybe<Scalars['String']>;
  regulation?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  source?: Maybe<DatabaseSource>;
  type?: Maybe<Scalars['String']>;
};

export type SanctionAddress = {
  __typename?: 'SanctionAddress';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  databaseId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  modified?: Maybe<Scalars['DateTime']>;
  moreInfo?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  place?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  registered?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  sanction?: Maybe<Sanction>;
  street?: Maybe<Scalars['String']>;
  streetNumer?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type SanctionAlt = {
  __typename?: 'SanctionAlt';
  country?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  databaseId?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  function?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  language?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  modified?: Maybe<Scalars['DateTime']>;
  moreInfo?: Maybe<Scalars['String']>;
  registerId?: Maybe<Scalars['String']>;
  registered?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  sanction?: Maybe<Sanction>;
  taxId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  vatNumber?: Maybe<Scalars['String']>;
  wholeName?: Maybe<Scalars['String']>;
};

export type SanctionBirth = {
  __typename?: 'SanctionBirth';
  circa?: Maybe<Scalars['Boolean']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  databaseId?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  dateFrom?: Maybe<Scalars['DateTime']>;
  dateString?: Maybe<Scalars['String']>;
  dateTo?: Maybe<Scalars['DateTime']>;
  day?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  modified?: Maybe<Scalars['DateTime']>;
  month?: Maybe<Scalars['Int']>;
  place?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  sanction?: Maybe<Sanction>;
  year?: Maybe<Scalars['Int']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type SanctionDoc = {
  __typename?: 'SanctionDoc';
  country?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  databaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  issuedBy?: Maybe<Scalars['String']>;
  knownExpired?: Maybe<Scalars['Boolean']>;
  modified?: Maybe<Scalars['DateTime']>;
  moreInfo?: Maybe<Scalars['String']>;
  nameOnDocument?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  sanction?: Maybe<Sanction>;
  type?: Maybe<Scalars['String']>;
  typeDescription?: Maybe<Scalars['String']>;
};

/** Session object type encapsulates group of checks and businesses and persons those checks will be performed on */
export type Session = {
  __typename?: 'Session';
  apiToken?: Maybe<CompanyToken>;
  apiTokenId?: Maybe<Scalars['ID']>;
  /** List of businesses checked in this session */
  businesses?: Maybe<Array<Maybe<Business>>>;
  /** List of checks performed in this session */
  checks?: Maybe<Array<Maybe<Check>>>;
  /** Session code */
  code?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['ID']>;
  configuration?: Maybe<Configuration>;
  /** Unique ID of the configuration used for creating this session */
  configurationId?: Maybe<Scalars['ID']>;
  cost?: Maybe<Scalars['Decimal']>;
  /** Date and time of session's creation */
  created?: Maybe<Scalars['DateTime']>;
  /** Date and time of session's deletion */
  deleted?: Maybe<Scalars['DateTime']>;
  /** Short description for this session */
  description?: Maybe<Scalars['String']>;
  /** This field contains value of error code if overall check status equals ERROR. Otherwise, field contains null */
  errorCode?: Maybe<Scalars['String']>;
  /** This field contains short description of error if overall check status equals ERROR. Otherwise, field contains null */
  errorDescription?: Maybe<Scalars['String']>;
  /** Session unique ID */
  id?: Maybe<Scalars['ID']>;
  /** Is test session */
  isTesting?: Maybe<Scalars['Boolean']>;
  /** Date and time of session's last modification */
  modified?: Maybe<Scalars['DateTime']>;
  /** Note for this session */
  note?: Maybe<Scalars['String']>;
  /** Overall check status represents status of all checks */
  overallCheckStatus?: Maybe<CheckStatus>;
  /** List of persons checked in this session */
  persons?: Maybe<Array<Maybe<Person>>>;
  price?: Maybe<Scalars['Decimal']>;
  /** This field contains external reference company identification related to this session. Otherwise, field contains null */
  referenceCompanyId?: Maybe<Scalars['String']>;
  /** Type of session's response transport */
  responseTransport?: Maybe<ResponseTransport>;
  /** Current status of the session */
  status?: Maybe<SessionStatus>;
  /** Name of the subject that is being checked. If subject is bussines this field contains company name. In case of subject being person this field contains person's full name */
  subjectName?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['ID']>;
  /** This field contains URL of the webhook used for receiving events when status of session or particular check is changed */
  webHook?: Maybe<Scalars['String']>;
};

/** Input object type used for passing objects with data related to particular session */
export type SessionInput = {
  /** List of businesses that will be checked in this session */
  businesses?: InputMaybe<Array<InputMaybe<BusinessInput>>>;
  /** List of checks that will be performed in this session */
  checks?: InputMaybe<Array<InputMaybe<CheckInput>>>;
  /** Session code */
  code?: InputMaybe<Scalars['String']>;
  /** ID of the configuration for the session */
  configurationId: Scalars['ID'];
  /** Short description of the session */
  description?: InputMaybe<Scalars['String']>;
  /** Unique session ID */
  id?: InputMaybe<Scalars['ID']>;
  /** Is test session */
  isTesting?: InputMaybe<Scalars['Boolean']>;
  /** List of persons that will be checked in this session */
  persons?: InputMaybe<Array<InputMaybe<PersonInput>>>;
  /** Unique ID of external referenced company */
  referenceCompanyId?: InputMaybe<Scalars['String']>;
  /** Unique ID of external referenced object */
  referenceId?: InputMaybe<Scalars['String']>;
  /** Unique ID of external referenced user */
  referenceUserId?: InputMaybe<Scalars['String']>;
  /** Type of session's response transport */
  responseTransport?: InputMaybe<ResponseTransport>;
  /** This field should contain URL of the webhook that will receive events when status of session or particular check is changed */
  webHook?: InputMaybe<Scalars['String']>;
};

/** Enumeration type that represents all statuses a session could have during its lifecycle */
export enum SessionStatus {
  /** This is initial status of the session after creation. */
  Created = 'CREATED',
  /** Session will have this status if its execution is completed and results are received. */
  Done = 'DONE',
  /** Session will have this status if it received initial data but can be updated in the future. This status is relevant only for sessions with the monitoring check types */
  Ongoing = 'ONGOING',
  /** Session will have this status if it is fetching and processing results for certain checks. It will remain on this status until it receives a response from a provider that it is completed */
  Processing = 'PROCESSING',
  /** Session will have this status if it is ready for processing of checks */
  Ready = 'READY'
}

/** SourceType description */
export enum SourceType {
  /** Business - description */
  Business = 'BUSINESS',
  /** BusinessWithPerson - description */
  BusinessWithPersons = 'BUSINESS_WITH_PERSONS',
  /** Person - description */
  Person = 'PERSON'
}

/** State description */
export type State = {
  __typename?: 'State';
  /** Country this state is part of */
  country?: Maybe<Country>;
  /** ID of the country this state is part of */
  countryId?: Maybe<Scalars['ID']>;
  /** State unique ID */
  id: Scalars['ID'];
  /** ISO code of the state */
  isoCode: Scalars['String'];
  /** Name of the state */
  name: Scalars['String'];
};

export type StateInput = {
  countryId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  isoCode: Scalars['String'];
  name: Scalars['String'];
};

export type Stats = {
  __typename?: 'Stats';
  company?: Maybe<Company>;
  companyCheck?: Maybe<CompanyCheck>;
  companyCheckId?: Maybe<Scalars['ID']>;
  companyId?: Maybe<Scalars['ID']>;
  configuration?: Maybe<Configuration>;
  configurationId?: Maybe<Scalars['ID']>;
  cost?: Maybe<Scalars['Decimal']>;
  count?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  price?: Maybe<Scalars['Decimal']>;
};

/** StatsMetric description */
export enum StatsMetric {
  /** CheckTypes - description */
  CheckTypes = 'CHECK_TYPES',
  /** Company - description */
  Company = 'COMPANY',
  /** CompanyCheck - description */
  CompanyCheck = 'COMPANY_CHECK',
  /** Configuration - description */
  Configuration = 'CONFIGURATION',
  /** Day - description */
  Day = 'DAY',
  /** Month - description */
  Month = 'MONTH',
  /** NoMetric - description */
  NoMetric = 'NO_METRIC',
  /** Session - description */
  Session = 'SESSION',
  /** SessionCheck - description */
  SessionCheck = 'SESSION_CHECK',
  /** Year - description */
  Year = 'YEAR'
}

export type StringValidation = Validation & {
  __typename?: 'StringValidation';
  /** Allowed max len for the field value. */
  maxLength?: Maybe<Scalars['Int']>;
  /** Allowed min len for the field value. */
  minLength?: Maybe<Scalars['Int']>;
  /** Allowed pattern for the field value. */
  pattern?: Maybe<Scalars['String']>;
  /** Is the field value required. */
  required?: Maybe<Scalars['Boolean']>;
};

/** Wrapper for countries and document types supported by government identity check. */
export type SupportedCountry = {
  __typename?: 'SupportedCountry';
  /** Country supported by goverment identity check */
  country?: Maybe<Country>;
  /** List of supported document types for given country */
  supportedDocumentTypes?: Maybe<Array<Maybe<DocumentType>>>;
};

export type Tag = {
  __typename?: 'Tag';
  color?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  shortCode: Scalars['String'];
  type?: Maybe<Scalars['String']>;
};

export type TagInput = {
  color: Scalars['String'];
  description: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  shortCode: Scalars['String'];
  type?: InputMaybe<Scalars['String']>;
};

export type TestBusinessGType = TestEntityGType & {
  __typename?: 'TestBusinessGType';
  business?: Maybe<Business>;
  checkType?: Maybe<CheckType>;
  /** If present, then this entity is usable only for given country. If null - applicable everywhere. */
  country?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

/** Test entity info. */
export type TestEntityGType = {
  checkType?: Maybe<CheckType>;
  /** If present, then this entity is usable only for given country. If null - applicable everywhere. */
  country?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type TestPersonGType = TestEntityGType & {
  __typename?: 'TestPersonGType';
  checkType?: Maybe<CheckType>;
  /** If present, then this entity is usable only for given country. If null - applicable everywhere. */
  country?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  person?: Maybe<Person>;
};

export type Transaction = {
  __typename?: 'Transaction';
  companyId?: Maybe<Scalars['ID']>;
  created?: Maybe<Scalars['DateTime']>;
  deleted?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  modified?: Maybe<Scalars['DateTime']>;
  payedAmount?: Maybe<Scalars['Decimal']>;
  status?: Maybe<TransactionStatus>;
  totalPrice?: Maybe<Scalars['Decimal']>;
};

/** Enumeration type that represents all statuses a transaction and invoice could have */
export enum TransactionStatus {
  /** Payment is canceled */
  Canceled = 'CANCELED',
  /** Transaction is executed and successfully completed */
  Completed = 'COMPLETED',
  /** Transaction is created */
  Created = 'CREATED',
  /** The invoice is sent and payment is awaited */
  Invoiced = 'INVOICED',
  /** Transaction was executed incorrectly because it was paid more than required */
  Overpaid = 'OVERPAID',
  /** Transaction was executed incorrectly because it was paid less than required */
  Underpaid = 'UNDERPAID',
  /** Transaction has this status there is no information about state of payment execution */
  Unknown = 'UNKNOWN'
}

export type Translation = {
  __typename?: 'Translation';
  id?: Maybe<Scalars['ID']>;
  key: Scalars['String'];
  language?: Maybe<Language>;
  languageId: Scalars['Int'];
  languageName?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

export type TranslationInput = {
  id?: InputMaybe<Scalars['ID']>;
  key: Scalars['String'];
  languageId: Scalars['Int'];
  value: Scalars['String'];
};

export type TranslationLanguage = {
  __typename?: 'TranslationLanguage';
  id?: Maybe<Scalars['ID']>;
  key: Scalars['String'];
  translations?: Maybe<Array<Maybe<Translation>>>;
};

/** User description */
export type User = {
  __typename?: 'User';
  /** description */
  address?: Maybe<Scalars['String']>;
  /** description */
  address2?: Maybe<Scalars['String']>;
  /** description */
  authToken?: Maybe<Scalars['String']>;
  /** description */
  avatarId?: Maybe<Scalars['ID']>;
  /** description */
  city?: Maybe<Scalars['String']>;
  /** description */
  companies?: Maybe<Array<Maybe<Company>>>;
  /** description */
  company?: Maybe<Company>;
  /** description */
  country?: Maybe<Country>;
  /** description */
  countryId?: Maybe<Scalars['ID']>;
  /** description */
  created?: Maybe<Scalars['DateTime']>;
  /** description */
  defaultCompany?: Maybe<Company>;
  /** description */
  deleted?: Maybe<Scalars['DateTime']>;
  /** description */
  email?: Maybe<Scalars['String']>;
  /** description */
  emailConfirmed?: Maybe<Scalars['Boolean']>;
  /** description */
  firstName: Scalars['String'];
  /** description */
  id?: Maybe<Scalars['ID']>;
  /** description */
  isLocked?: Maybe<Scalars['Boolean']>;
  /** description */
  isPhoneValid?: Maybe<Scalars['Boolean']>;
  /** description */
  language?: Maybe<Language>;
  /** description */
  languageId?: Maybe<Scalars['Int']>;
  /** description */
  lastLogin?: Maybe<Scalars['DateTime']>;
  /** description */
  lastName: Scalars['String'];
  /** description */
  lockoutEnd?: Maybe<Scalars['DateTime']>;
  /** description */
  middleName?: Maybe<Scalars['String']>;
  /** description */
  modified?: Maybe<Scalars['DateTime']>;
  /** description */
  permissions?: Maybe<Array<Maybe<Permission>>>;
  /** description */
  phoneNumber?: Maybe<Scalars['String']>;
  /** description */
  roles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** description */
  state?: Maybe<Scalars['String']>;
  /** description */
  title?: Maybe<Scalars['String']>;
  /** description */
  zip?: Maybe<Scalars['String']>;
};

export type UserInput = {
  address?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  avatarId?: InputMaybe<Scalars['ID']>;
  city?: InputMaybe<Scalars['String']>;
  countryId?: InputMaybe<Scalars['ID']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  iAgree?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  languageId?: InputMaybe<Scalars['ID']>;
  lastName?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

export type Validation = {
  /** Is the field value required. */
  required?: Maybe<Scalars['Boolean']>;
};
